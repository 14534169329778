.headerSection{
    background-color: $white;
    padding:10px;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    .plusIconAnt{
        svg{
            fill: $primary-color;
        }
        
        font-size: 30px;
    }

    .allReports{
        background-color: $lightGray;
        border: none;
        border-radius: 5px;

        .rightOutLined{
            svg{
                fill: $secondary_color;
            }
        }
    }

    .iconSize24{
        font-size: 20px;
    }

    .printerIcon{
        background-color: #9ABCEF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.13);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        padding: 6px;
        .span{
            font-size: 20px;
        }
    }

    .datePickerLKS{
        background-color: $lightGray;
        border-radius: 30px;
        border: none;
        height: 38px;
        min-width: 175px;
    }
}

.fs_tables{
    .ant-table{
        background:transparent;
    }
    .status{
        background-color: #E3E3E3;
        font-size: 12px;
        text-align: center;
        padding: 3px 0px;
        display: block;
        border-radius: 30px;
        color: $darkGray;
    }

    .status.done{
        color: $primary-color;
    }

    .viewBtts{
        background-color: $primary-color;
        color: $white;
        padding: 3px 20px;
        font-size: 12px;
        border-radius: 5px;
    }

    .linkedText{
        color: $primary-color;
    }

    thead{
        tr{
            th{
                background-color: #E8E3E3;
                margin-bottom: 10px;
                padding-top: 8px;
                padding-bottom: 8px;
                text-align: center;
                border: none;
                &:before{
                    content:none !important;
                }
            }
        }
    }

    tbody{
        &:before{
            content: "-";
            display: block;
            line-height: 1em;
            color: transparent;
        }
        tr{
            td{
                text-align: center;
                background-color: $white;
            }
        }
    }
}

.cstmPagination{

    .ant-pagination-pre, .ant-pagination-next{
        .anticon{
            position: relative;
            top:-3px;
        }
    }
    button{
        padding: 0;
        background: transparent !important;
        border: none !important;
    }

    .ant-pagination-item{
        border-radius: 50%;
        border: none;
        background:transparent;
        font-weight: 500;
    }

    .ant-pagination-item-active{
        background: #9ABCEF;
    }
}

.allReportsWrapper{
    .ant-dropdown-menu-item {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12)!important;
        &:last-child{
            border-bottom: none !important;
        }
    }
}

.reportName{
    color: $primary-color;
}

.tabSection{
    background-color: #E8E3E3;
    display: flex;
    align-items: center;
    border-radius: 5px;
    li{
        list-style: none;
        a{
            display: block;
            color: $black;
            padding: 10px 40px;
            font-size: 14px;
        }

        a.active{
            background-color: #9ABCEF;
        }
    }
}

.tabsContentSection{
    .tabContent{
        display: none;
    }

    .tabContent.active{
        display: block;
    }
}

.reportDetailsTable {
    width: -webkit-fill-available;
    margin-right:5px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.06);
    border: 1px solid #E4E2E2;
    .rtableChild {
        &:nth-of-type(odd){
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
            background-color: $white;
        }

        &:nth-of-type(even) {
            background-color: rgba(255, 255, 255, 0.7);
        }

        li {
            font-size: 13px;
            color: rgba(0, 0, 0, 0.7);
            padding: 15px 15px;
            list-style: none;
            height: 50px;
        }
    }

    .rSummeryTable{
        &:nth-of-type(odd) {
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
            background-color: $white;
        }
    
        &:nth-of-type(even) {
            background-color: rgba(64, 116, 249, 0.1);
        }
        li {
            font-size: 13px;
            color: rgba(0, 0, 0, 0.7);
            padding: 15px 15px;
            list-style: none;
            height: 50px;
        }
    }
}