.notificationBttns {
  background-color: $gray;
  border: none;
  margin: 0 2px;
  padding: 7px 0;
  border-radius: 8px;
  font-size: 12px;
  color: #acaaaa;
}

.notificationBttns.active {
  color: $black;
}

.btnPrimary {
  background-color: $primary_color;
  color: $white;
  border: 1px solid transparent;
  border-radius: 100px;
  height: 50px;
  font-size: 1rem;
  font-weight: 500;
  padding: 8px 18px;
  min-width: 2rem;
  width: 100%;
  transition: all 0.3s ease;

  &:hover,
  &:active,
  &:focus {
    background-color: $primary_color_hover;
    border-color: $primary_color_hover;
    color: $white;
    box-shadow: none;
    outline: none;
  }
}

.btnSecondary {
  background-color: transparent;
  color: $black;
  border: 1px solid $secondary_color;
  border-radius: 100px;
  height: 50px;
  font-size: 1rem;
  font-weight: 500;
  padding: 8px 18px;
  min-width: 2rem;
  width: 100%;
  transition: all 0.3s ease;

  &:hover,
  &:active,
  &:focus {
    background-color: $lightGray;
    border-color: $lightGray;
    color: $black;
    box-shadow: none;
    outline: none;
  }
}
