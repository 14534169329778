$primary_color: #4074f9;
$primary_color_hover: #2861f2;
$secondary_color: #bebaba;
$gray: #f6f8ff;
$secondary_purple: #e0eaff;
$lightGray: #f2f2f2;
$white: #fff;
$black: #000;
$darkGray: #4d4d4d;
$theme_green: #2fae1a;
$theme_red: #db1212;

// Font Families
$inter: 'Inter', sans-serif;
$abhaya: 'Abhaya Libre', serif;

@for $i from 0 through 300 {
  [class*='fsize-#{$i}'] {
    font-size: #{$i}px !important;
  }

  [class*='fweight-#{$i}'] {
    font-weight: #{$i * 100} !important;
  }

  [class*='round-#{$i}'] {
    border-radius: #{$i}px !important;
  }

  [class*='roundrem-#{$i}'] {
    $j: calc($i/10);
    border-radius: #{$j}rem !important;
  }

  $percent: '%';

  // Percentage
  [class*='w-per-#{$i}'] {
    width: #{$i}+ $percent !important;
  }

  [class*='mxp-width-#{$i}'] {
    max-width: #{$i}px !important;
  }

  [class*='h-per-#{$i}'] {
    height: #{$i}+ $percent !important;
  }

  [class*='wpx-#{$i}'] {
    width: #{$i}px !important;
  }

  [class*='hpx-#{$i}'] {
    height: #{$i}px !important;
  }

  [class*='hvh-#{$i}'] {
    height: #{$i}vh !important;
  }

  [class*='wrem-#{$i}'] {
    width: #{$i}rem !important;
  }

  [class*='hrem-#{$i}'] {
    height: #{$i}rem !important;
  }

  /* Positions */
  [class*='topPx-#{$i}'] {
    top: #{$i}px;
  }

  [class*='leftPx-#{$i}'] {
    left: #{$i}px;
  }

  [class*='rightPx-#{$i}'] {
    right: #{$i}px;
  }

  [class*='bottomPx-#{$i}'] {
    bottom: #{$i}px;
  }

  // Percentage
  [class*='top-percent-#{$i}'] {
    top: #{$i}+ $percent;
  }

  [class*='left-percent-#{$i}'] {
    left: #{$i}+ $percent;
  }

  [class*='right-percent-#{$i}'] {
    right: #{$i}+ $percent;
  }

  [class*='bottom-percent-#{$i}'] {
    bottom: #{$i}+ $percent;
  }

  [class*='translate-neg-#{$i}-#{$i}'] {
    transform: translate(-#{$i}+ $percent, -#{$i}+ $percent);
  }

  // Position Rem
  [class*='top-rem-#{$i}'] {
    top: #{$i}rem;
  }

  [class*='left-rem-#{$i}'] {
    left: #{$i}rem;
  }

  [class*='right-rem-#{$i}'] {
    right: #{$i}rem;
  }

  [class*='bottom-rem-#{$i}'] {
    bottom: #{$i}rem;
  }

  [class*='mbPx-#{$i}'] {
    margin-bottom: #{$i}px;
  }

  [class*='mtPx-#{$i}'] {
    margin-top: #{$i}px;
  }

  [class*='truncateLine-#{$i}'] {
    -webkit-line-clamp: #{$i};
  }

  // zindex
  [class*='zindex-#{$i}'] {
    z-index: #{$i} !important;
  }

  [class*='maxWidth-#{$i}'] {
    max-width: #{$i}rem !important;
  }
  [class*='minWidth-#{$i}'] {
    min-width: #{$i}rem !important;
  }
}

.bgLight {
  background-color: $white !important;
}

.textBColor {
  color: $black !important;
}

.themeTextGreen {
  color: $theme_green !important;
}

.themeTextRed {
  color: $theme_red !important;
}

.textWColor {
  color: $white;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.secondaryBttn {
  background-color: $secondary_purple;
}

.hoverLink {
  &:hover {
    color: $primary_color !important;
  }
}
