.backtesting_criteria {
}

.mainHeading {
  font-size: 30px;
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-bottom: 0;
}

.labelDiv label {
}

.ant-picker,
.ant-input {
  /* width: 100% !important; */
  /* background: gainsboro !important; */
  /* border-radius: 5px !important;
  height: 38px; */
  /* border: transparent; */
}
.ant-input-affix-wrapper {
  padding: 0 !important;
}
/* .ant-input-affix-wrapper > input.ant-input {
  background: transparent !important;
  height: 40px;
  width: 95% !important;
  padding-left: 2px !important;
} */
.ant-select {
  border: transparent;
  width: 100% !important;
  /* background: gainsboro !important; */
  border-radius: 5px !important;
}

.ant-picker input {
  font-size: 14px !important;
}

.ant-select-selection-item {
  font-size: 14px;
  font-weight: 500;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 23px;
  line-height: 21px;
  background: #1976d2;
  color: #fff;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item svg path {
  fill: #fff;
}

.ant-select-selection-overflow {
  margin-top: 0;
}

/* .ant-tooltip-inner{
    background-color: transparent;
    color: #1976d2;
    font-size: 12px;
    box-shadow: none;
}

.ant-tooltip-arrow{
    display: none;
}

.ant-slider-tooltip{
    top: 244px !important;
} */

.ant-slider-handle.ant-tooltip-open {
  background-color: #1976d2;
  border-color: #1976d2;
}

.ant-slider-track {
  background-color: #1976d2;
}

.ant-checkbox-checked:after {
  border-color: #1976d2;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1976d2;
  border-color: #1976d2;
}

.border-1 {
  border: 1px solid gainsboro;
  padding: 15px 15px 0 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.checkboxUL {
  padding-left: 0;
}

.checkboxUL li {
  list-style: none;
  margin-right: 10px;
}

.checkboxUL li:last-child {
  margin-right: 0;
}

.LI_5 li {
  width: 20%;
}

.LI_3 li {
  width: 33.3%;
}

.LI_4 li {
  width: 25%;
}

.expression {
  padding-left: 50px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  height: 60px;
  font-size: 14px;
  background-color: gainsboro;
}
