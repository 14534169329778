.ant-form-item {
  margin-bottom: 16px;
  .ant-form-item-label {
    label {
      color: $black;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      &:before {
        content: '' !important;
      }
    }
  }
}

.form-control {
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  &:focus,
  &:active,
  &:hover {
    border-color: $primary_color;
    box-shadow: none;
    outline: none;
  }
  &::placeholder {
    color: #b5b5b5;
  }
}

.rememberMe {
  span {
    font-size: 12px;
    color: #918c8c;
  }

  span:last-child {
    position: relative;
    top: -2px;
  }
}

.forgotPassword {
  font-size: 12px;
  color: $primary_color;
}

.allBtns {
  width: auto;
  height: 40px;
  padding: 0 20px;
  background-color: $primary_color;
  border-radius: 5px;
  span {
    color: $white;
    font-weight: 600;
  }

  &:hover {
    border-color: $primary-color;
    span {
      color: $primary_color;
    }
  }
}

.bttns {
  background-color: $gray;
  color: $black !important;
}

.bttn.processing {
  color: #ce1e1e !important;
}

.customRadioButton {
  padding-left: 35px;
  margin-right: 50px;
  position: relative;
  margin-top: 5px;
  span {
    width: 25px;
    height: 25px;
    border: 1px solid $black;
    border-radius: 50px;
    display: flex;
    position: absolute;
    left: 0;
    line-height: 0;
    cursor: pointer;
  }
}

.customRadioInput:checked + .radioMark {
  box-shadow: inset 0 0 0 0.2em #fff !important;
  background-color: $black;
}

.Upload {
  background-color: $secondary_purple;
}

.form-label {
  font-size: 14px;
  font-weight: 500;
}

.ant-input-affix-wrapper {
  padding: 8px 15px !important;
}

/* =========== Form Input ==========*/

.vi__character {
  &.vi__character--selected {
    border-color: rgb(var(--color-blue-2));
    outline: none;
  }
}
