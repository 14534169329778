$topband-bg: #21263c;
$topband-font-color: #f0f0f0;
$sidebar-font-color: #181c2e;

// body {
//   display: inherit !important;
// }
// body,
// button,
// input,
// optgroup,
// select,
// textarea,
// .dropdownOverlay {
//   font-family: 'Poppins', sans-serif !important;
// }
// body,
// #root {
//   padding: 0;
//   margin: 0;
//   // font-size: 13px;
//   font-family: 'Poppins', sans-serif !important;
//   max-width: 100%;
// }

// // Card Table
// table {
//   border-spacing: 0 8px;

//   .ant-table-thead {
//     // font-family: $font_family_inter;
//     font-family: 'Poppins', sans-serif !important;

//     .ant-table-cell {
//       // font-family: $font_family_inter;
//       font-family: 'Poppins', sans-serif !important;
//       font-weight: 400 !important;
//     }
//   }
// }

:root,
.light {
  /* https://tailwindcss.com/docs/customizing-colors#using-css-variables */

  --color-white: 255 255 255; /* #ffffff */
  --color-black: 0 0 0; /* #000000 */

  --color-gray-1: 179 179 179; /* gray #b3b3b3*/
  --color-gray-2: 84 84 84; /* dark gray #545454 */
  --color-gray-3: 18 18 18; /* smoke #121212 */
  --color-gray-4: 193, 193, 193; /* smoke #c1c1c1 */
  --color-gray-5: 45 42 49; /* smokeDark #2D2A31 */

  --color-blue-1: 143 199 255; /* basin #8fc7ff */
  --color-blue-2: 46 141 238; /* gulf #2e8dee */
  --color-blue-3: 32 129 226; /* sea blue #2081e2 */
  --color-blue-4: 24 104 183; /* dark sea blue #1868b7 */
  --color-blue-5: 35 90 163; /* offshore #235aa3 */

  --color-green-1: 104 242 171; /* light seagrass #68f2ab */
  --color-green-2: 52 199 123; /* seagrass #34c77b */
  --color-green-3: 12 149 78; /* dark seagrass #0c954e */

  --color-yellow-1: 246 192 0; /* starfish #f6c000 */
  --color-yellow-2: 197 158 16; /* dark starfish #c59e10 */

  --color-red-1: 235 107 107; /* light coral #eb6b6b */
  --color-red-2: 235 87 87; /* coral #eb5757 */
  --color-red-3: 230 41 41; /* dark coral #e62929 */

  --color-accent-red: var(--color-red-3); /* dark coral */
  --color-accent-purple: 93 50 233; /* octopus #5d32e9 */
  --color-accent-fuchsia: 233 50 190; /* pink fish #e932be */
  --color-accent-orange: 242 153 74; /* seahorse #f2994a */
  --color-accent-cerulean: 21 178 229; /* marina blue #15b2e5 */
  --color-accent-aqua: 43 205 228; /* aqua #2bcde4 */

  --color-base-1: var(--color-white);
  --color-base-2: 246 246 246; /* #f6f6f6 */
  // --color-base-3: 246 246 246;
  // --color-base-1: var(--color-gray-3);

  // --color-base-1: 21 23 28;
  // --color-base-2: 27 27 27; /* #1b1b1b */
  // --color-base-2: 252 252 252; /* #1b1b1b */
  // --color-base-3: 20 20 20;
  // --color-base-3: 28 29 34;
  /* #f6f6f6 */

  --color-component-gray-1: rgb(var(--color-gray-3) / 4%);
  --color-component-gray-2: rgb(var(--color-gray-3) / 8%);
  --color-component-gray-3: rgb(var(--color-gray-3) / 12%);
  --color-component-gray-4: rgb(var(--color-gray-3) / 20%);

  --color-elevation-1: var(--color-white);
  --color-elevation-1-subtle: var(--color-white);
  --color-elevation-2: var(--color-white);
  --color-elevation-3: var(--color-white);

  --color-text-primary: var(--color-gray-3);
  --color-text-secondary: var(--color-gray-2);
  --color-text-interactive-primary: var(--color-blue-3);
  --color-text-interactive-primary-hover: var(--color-blue-5);
  --color-text-interactive-secondary: var(--color-gray-3);
  --color-text-interactive-secondary-hover: var(--color-gray-2);

  --color-interactive-icon-primary: var(--color-gray-3);
  --color-interactive-icon-primary-hover: var(--color-gray-2);
  --color-interactive-icon-secondary: var(--color-gray-2);
  --color-interactive-icon-secondary-hover: var(--color-gray-3);

  --color-border-1: rgb(var(--color-gray-3) / 8%);
  --color-border-2: rgb(var(--color-gray-3) / 12%);
  --color-border-3: rgb(var(--color-gray-3) / 32%);
  --color-border-4: rgb(241 241 244);

  --shadow-elevation-1-subtle: 0px 4px 16px rgb(var(--color-black) / 4%);
  --shadow-elevation-1: 0px 4px 16px rgb(var(--color-black) / 8%);
  --shadow-elevation-2: 0px 6px 24px rgb(var(--color-black) / 12%);
  --shadow-elevation-3: 0px 6px 32px rgb(var(--color-black) / 20%);

  // --primary-1: 2 133 199; /* offshore #0284c7 sky blue */
  // --primary-2: 2 133 199; /* offshore #0284c7 sky blue */
  --primary-1: 64 116 249; /* offshore #0284c7 sky blue */
  --primary-2: 40 100 255; /* offshore #0284c7 sky blue */

  --color-gray-5: 246 246 246;
  --color-gray-6: 236 236 236;
  --color-gray-7: 255 255 255;
  --color-gray-8: 232 232 232;
  --color-gray-9: 240 240 240;
  --color-gray-10: 255 255 255;
  --color-gray-11: 255 255 255;

  // --color-shadow-soft: 6px 6px 12px #b8b9be, -6px -6px 12px #fff; /* smoke #121212 */

  --color-shadow-soft: 6px 6px 12px #ececec40, -6px -6px 12px #ffffffb8; /* smoke #121212 */
  --color-shadow-card: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);

  color: rgb(var(--color-text-primary));
}

.dark {
  // --color-base-1: var(--color-gray-3);
  --color-base-1: 21 23 28;
  // --color-base-2: 27 27 27; /* #1b1b1b */
  // --color-base-2: 15 16 20; /* #1b1b1b */
  --color-base-2: 39 41 46; /* #1b1b1b */
  // --color-base-3: 20 20 20;
  --color-base-3: 28 29 34;
  --color-base-4: 15 16 20;

  --color-hover: 28 29 34;

  --color-gray-100: 27 28 34;
  --color-gray-200: 38 39 47;
  --color-gray-300: 49 51 58;

  --color-base-header-1: 13 14 18;

  --color-component-gray-1: rgb(var(--color-white) / 4%);
  --color-component-gray-2: rgb(var(--color-white) / 8%);
  --color-component-gray-3: rgb(var(--color-white) / 12%);
  --color-component-gray-4: rgb(var(--color-white) / 20%);

  --color-border-1: rgb(var(--color-white) / 8%);
  --color-border-2: rgb(var(--color-white) / 12%);
  // --color-border-2: rgb(30 32 39);
  --color-border-3: rgb(var(--color-white) / 32%);
  --color-border-4: rgba(20, 21, 26, 0.534);

  --color-elevation-1: 32 32 32; /* #202020 */
  --color-elevation-1-subtle: var(--color-elevation-1);
  --color-elevation-2: 37 37 37; /* #252525 */
  --color-elevation-3: 46 46 46; /* #2e2e2e */

  --color-text-primary: var(--color-white);
  --color-text-secondary: var(--color-gray-1);
  --color-text-interactive-primary: var(--color-blue-1);
  --color-text-interactive-primary-hover: var(--color-blue-3);
  --color-text-interactive-secondary: var(--color-white);
  --color-text-interactive-secondary-hover: var(--color-gray-1);

  --color-interactive-icon-primary: var(--color-white);
  --color-interactive-icon-primary-hover: var(--color-gray-1);
  --color-interactive-icon-secondary: var(--color-gray-1);
  --color-interactive-icon-secondary-hover: var(--color-white);

  // --primary-1: 69 76 154; /* offshore #8c1aff purple */
  // --primary-2: 124 58 237; /* offshore #8c1aff light purple */
  --primary-1: 64 116 249; /* offshore #0284c7 sky blue */
  --primary-2: 40 100 255; /* offshore #0284c7 sky blue */

  --color-shadow-soft: 6px 6px 12px #1d1b1b73, -6px -6px 12px #111111a8; /* smoke #121212 */

  --color-gray-4: 193, 193, 193; /* smoke #c1c1c1 */
  --color-gray-5: 41 38 44; /* smokeDark #2D2A31 */
  --color-gray-6: 45 40 52;
  --color-gray-7: 29 24 34;
  --color-gray-8: 60 54 67;
  --color-gray-9: 35 30 40;
  --color-gray-10: 45 40 52;
  --color-gray-11: 255 255 255;
  /* smokeDark #2D2A31 */

  color: rgb(var(--color-text-primary));
}

// :root {
//   background: rgb(var(--color-base-1));
// }
// body {
//   min-height: 100vh;
//   background: rgb(var(--color-base-1));
// }
