$enable-important-utilities: false; //this disables !important
// @import '../../../node_modules/bootstrap/scss/bootstrap.scss';
// @import '../../../node_modules/bootstrap/scss/bootstrap';
@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700&family=Inter:wght@100;200;300;400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './variable_2.scss';
// Predefined Classes
@import './mixins.scss';
@import './veriable.scss';

// Components
@import './components/form.scss';
@import './components/button';
@import './components/header.scss';
@import './components/quillEditor.scss';

// Pages
@import './pages/theme.scss';
@import './pages/login.scss';
@import './pages/report.scss';

// New Design
@import './pages/NewDesign';
@import './pages/broker';
@import './pages/custom';
@import './pages/style-v1';

@import './theme.scss';
