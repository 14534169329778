.header {
  background: #0b6163;
}
.header-inner {
  max-width: 1200px;
  /* margin: 0 auto; */
  padding: 15px 25px;
}

.clockD {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  margin-left: 5px;
}

.MuiDataGrid-columnHeaderTitle {
  color: rgba(28, 27, 33, 0.5);
}

.buy-sell-pointer {
  cursor: pointer;
}

.react-stockcharts-labelannotation text:hover::after {
  content: "ascasc";
  width: 60px;
  height: 48px;
  background: yellow;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

.select-custom-alert.ant-select {
  background: #f5f8fa !important;
  box-shadow: 0.5px 1px 3px #2146a4 !important;
  color: #2146a4 !important;
  border-radius: 5% !important;
}

.rti--container {
  background: rgb(220, 220, 220) !important;
}
.rti--input {
  background: rgb(220, 220, 220) !important;
  /* border: 1px solid blue !important; */
  width: 68% !important;
}
