.pdfMainBody {
  background-color: white;
  max-width: 700px;
  margin: auto;
  position: relative;
  /* display: none; */
  visibility: hidden;
  max-height: 0;

  /* max-width: 100px; */
  /* max-width: "10%"; */
}
.HideTable {
  display: none;
}
.reportPDF {
  background-color: #f5f0f0;
  padding: 50px 30px;
}

.reportPDF h4 {
  margin-top: 30px;
  font-weight: 600;
}

.logo_img {
  width: 100%;
  max-width: 300px;
}

.width100 {
  width: 100%;
}

.dateTimeGradient {
  background: linear-gradient(
    90deg,
    rgba(35, 144, 203, 0.2) 0%,
    rgba(112, 163, 0, 0.2) 53.82%,
    rgba(196, 57, 0, 0.2) 100%
  );
  max-width: max-content;
  padding: 8px 20px;
  border-radius: 5px;
}

.dateTimeGradient span {
  color: black;
}

.m-t-20 {
  margin-top: 40px;
}

.m-b-20 {
  margin-bottom: 40px;
}

.reportSummarySection {
  padding: 0 20px;
  margin-bottom: 20px;
}

.pdfFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20px;
  background: linear-gradient(270deg, #2390cb 0%, #70a300 53.82%, #c43900 100%);
}

.placeholderLogo {
  position: relative;
}

.placeholderImg {
  position: absolute;
  z-index: 0;
  opacity: 0.04;
  top: 14%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 75%;
  margin: auto;
}

.reportDetailsTable {
  width: -webkit-fill-available;
  margin-right: 5px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 4px 4px 5px rgb(0 0 0 / 6%);
  border: 1px solid #e4e2e2;
}

.reportDetailsTable .rSummeryTable:nth-of-type(odd) {
  box-shadow: 1px 1px 10px rgb(0 0 0 / 12%);
  background-color: #fff;
}

.reportDetailsTable .rSummeryTable:nth-of-type(even) {
  background-color: rgba(64, 116, 249, 0.1);
}

.justify-content-between {
  justify-content: space-between !important;
}

.d-flex {
  display: flex !important;
}

.reportDetailsTable .rSummeryTable li {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.7);
  padding: 15px 15px;
  list-style: none;
  height: 50px;
}

.configDetailsTable {
  width: -webkit-fill-available;
  margin-right: 5px;
  border-radius: 5px;
  overflow: hidden;
  border-right: 1px solid #e4e2e2;
}

.configDetailsTable .rSummeryTable:nth-of-type(odd) {
  background-color: transparent;
}

.configDetailsTable .rSummeryTable:nth-of-type(even) {
  background-color: transparent;
}

.configDetailsTable .rSummeryTable li {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.7);
  padding: 15px 15px;
  list-style: none;
  height: 50px;
}

.stock {
  width: 10%;
  margin-left: 10px;
  margin-right: 5px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid transparent;
}

.stockValue {
  border: 1px solid transparent;
  max-width: 83%;
  text-align: right;
  margin-right: 10px;
  word-wrap: break-word;
}

.graph {
  max-width: 1000px;
  margin: auto;
  border: 1px solid transparent;
  /* visibility: hidden; */
}
.graphViewer {
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
}

.pdf {
  display: none;
}

.MuiButton-root
  .MuiButton-text
  .MuiButton-textPrimary
  .MuiButton-sizeSmall
  .MuiButton-textSizeSmall
  .MuiButtonBase-root
  .css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  display: none !important;
}
@media only print {
  .pdfMainBody {
    background-color: white;
    max-width: 2480px;
    margin: auto;
    position: relative;
    display: block;
    visibility: visible;
  }
  .HideTable {
    display: block;
  }
  .graphViewer {
    margin: auto;
    width: 100% !important;
    height: 100%;
    border: 1px solid transparent;
  }

  .graph {
    /* max-width: 95% !important; */
    display: block;
    visibility: visible;
    page-break-inside: avoid;
  }
  .reportSummarySection {
    padding-top: 20px;
    page-break-inside: avoid;
  }

  @page {
    size: auto; /* auto is the initial value */
    margin: 0mm;
    margin-top: 3px;
    /* this affects the margin in the printer settings */
  }
  html {
    background-color: #ffffff;
    margin: 0px; /* this affects the margin on the HTML before sending to printer */
    margin-top: 3px;
  }
}

.MuiDataGrid-virtualScroller {
  min-height: 100px !important;
}
