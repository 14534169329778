// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  // font-family: 'Poppins', sans-serif !important;
  font-family: 'Inter', sans-serif;
  background-color: #fff !important;
}

.belowhead ul li {
  list-style: none;
  display: inline-block;
  border: solid 1px #ddd;
  margin-right: 5px;
  margin-left: 5px;
  padding: 2px 15px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.latest_update {
  position: absolute;
  padding-right: 45px;
  left: 260px;
  font-size: 14px;
  width: 325px;
  display: flex;

  span {
    &:nth-child(1) {
      width: 40%;
    }

    &:nth-child(2) {
      width: 60%;
    }
  }
}

.belowhead ul li span {
  font-size: 15px;
  line-height: 25px;
  vertical-align: middle;
}

.head_txt_mute {
  color: #797777;
  margin-right: 5px;
}

.back_btn {
  background-color: #e0eaff;
  border-radius: 100%;
  padding: 5px;
  margin-right: 0 !important;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.belowhead_lst_sec {
  width: 16%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.belowhead_mid_sec {
  width: 68%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.brnd_nm_sec {
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.aside_data {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  padding: 0px 10px;
  border: solid 1px #ddd;
  border-radius: 7px;
}

.aside_data span:nth-child(1) {
  margin-right: 5px;
}

.aside_data span:nth-child(2) {
  margin-right: 5px;
  color: #43a233;
}

.got_it {
  background-color: #e0eaff !important;
  border: solid 1px #9bcefd !important;
}

.got_it:hover {
  background-color: #4074f9 !important;
  transition: all 0.7s;
  color: #fff;
}

.bck_sec {
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_brnd_sts_active {
  display: inline-block;
  background-color: #43a233;
  color: #fff;
  text-align: center;
  font-size: 12px;
  border-radius: 30px;
  padding: 3px 15px;
  line-height: 20px;
}

.btn_brnd_sts_inactive {
  display: inline-block;
  background-color: #e1e1e1;
  color: #000;
  text-align: center;
  font-size: 12px;
  border-radius: 30px;
  padding: 3px 15px;
  line-height: 20px;
}

.brnd_nm {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 2px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.belowhead_lst_sec button {
  background-color: #e0eaff;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  margin-right: 5px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.updt_info ul li {
  list-style: none;
  padding: 15px;
  border-right: solid 1px #d2d2d2;
  font-size: 15px;
  position: relative;
  font-weight: 500;
  width: 25%;
}

.updt_info ul {
  border: solid 1px #d2d2d2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.updt_info ul li:nth-last-child(1) {
  border: none;
}

.updt_info ul li:nth-child(1) {
  padding-right: 40px !important;
  text-align: center;
  // width: 22%;
}

.updt_info ul li:nth-child(2) {
  // width: 15%;
  text-align: center;
}

.updt_info ul li:nth-child(3) {
  // width: 15%;
  text-align: center;
}

.updt_info ul li:nth-child(4) {
  // width: 15%;
  text-align: center;
}

.updt_info ul li:nth-child(5) {
  // width: 20%;
  text-align: center;
}

.updt_info {
  margin-top: 15px;
}
.circle {
  position: absolute;
  transform: translate(-87%, 50%);
  width: 8px;
  height: 8px;
  background-color: #089981;
  color: #fff;
  text-align: center;
  line-height: 10px;
  border-radius: 50%;
  top: 3px;
}
.float-right.d-flex > div {
  width: 140px;
  display: flex;
  justify-content: end;
  height: 35px;
}

.circle:hover {
  cursor: pointer;
}

.circle::after,
.circle::before {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  width: 8px;
  height: 8px;
  background: #089981;
  border-radius: 50%;
  z-index: -1;
  animation: grow 1s ease-in-out infinite;
}

// .circle::after {
//   background: #5f84ff;
// }
.indicator-fld span:nth-child(2) {
  padding-left: 10px;
}
.circle::after::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  background: #5f84ff;
  border-radius: 50%;
  z-index: -1;
  -webkit-animation: grow 1s ease-in-out infinite;
  animation: grow 1s ease-in-out infinite;
}

// .circle::before {
//   background:  #5f84ff;
//   -webkit-animation-delay: -0.5s;
//   animation-delay: -0.5s;
// }

// .indicator {
//   background-color: #d2d2d2;
//   color: #fff;
//   height: 8px;
//   line-height: 10px;
//   text-align: center;
//   top: 3px;
//   -webkit-transform: translate(-87%, 50%);
//   transform: translate(-87%, 50%);
//   width: 8px;
//   position: absolute;
//   border-radius: 100%;
// }
// .indicator:hover {
//   cursor: pointer;
// }
.indicator::after,
.indicator::before {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  width: 8px;
  height: 8px;
  background: #d2d2d2;
  border-radius: 50%;
  z-index: -1;
  // -webkit-animation: grow 1s ease-in-out infinite;
  // animation: grow 1s ease-in-out infinite;
}
// .indicator::after {
//   background: rgba(255, 95, 122, 0.4);
// }

// .indicator::after::before {
//   content: '';
//   display: block;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100px;
//   height: 100px;
//   background: #5f84ff;
//   border-radius: 50%;
//   z-index: -1;
//   -webkit-animation: grow 1s ease-in-out infinite;
//   animation: grow 1s ease-in-out infinite;
// }

@-webkit-keyframes grow {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }

  100% {
    transform: scale(1.8, 1.8);
    opacity: 0;
  }
}

@keyframes grow {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }

  100% {
    transform: scale(1.8, 1.8);
    opacity: 0;
  }
}

.primary_color {
  color: #4074f9;
}

.primary_red {
  color: #db1212;
}

.chrt_data {
  /* min-height: 480px; */
  background-color: #fff;
}

.TVChartContainer {
  height: 95vh;
}

.new_tabs {
  list-style-type: none;
}

.new_tabs li {
  margin-right: 10px;
}

.new_tabs li a {
  background-color: white;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  min-width: 38px;
  padding: 0 10px;
}

.new_tabs li a.active {
  border-color: #4074f9;
  color: #4074f9;
}

.strategyTabs li a {
  border-radius: 10px 10px 0 0 !important;
  font-size: 16px !important;
  padding: 10px 10px !important;
  height: auto;
  font-weight: 500;
  border-color: #7f7f7f;
  border-bottom: none;
  min-width: 110px;
}

.strategyTabs li a.active {
  border-bottom: none;
  background-color: #e0eaff;
}

.additionl_inf {
  border: solid 1px #d4d4d4;
  padding: 5px 15px;
  border-radius: 8px;
  background-color: #fff;
  font-size: 14px;
}

.primary_green {
  color: #43a233;
  font-weight: 600;
}
.order_list_bx {
  height: 65vh;
  overflow: auto;
  background-color: #fff;
  padding: 5px;
}

.order_list.no_order {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.order_list.no_order h3 {
  font-size: 14px;
}

.order_list_head h3 {
  font-size: 18px;
  margin-bottom: 0;
}

.order_list_head {
  display: flex;
  align-items: center;
  justify-content: start;
}

.order_list_head button {
  background-color: #c8c8c8;
  border: solid 1px;
  border-color: #d4d4d4;
  padding: 7px 20px;
  border-radius: 10px 10px 0 0px;
  border-bottom: none;
}

.order_list_head .btn_kill {
  background-color: #db1212;
  border: none;
  color: #fff;
  padding: 7px 20px;
  border-radius: 10px 10px 0 0px;
}

.order_list_head h3 {
  font-size: 17px;
  margin-bottom: 0;
}

.btn_calandar {
  background-color: #fff !important;
  padding: 7px 22px;
  border-radius: 10px 10px 0 0;
  margin-left: 5px;
  border-color: #d4d4d4;
  border-bottom: none;
}

.btn_calandar:focus {
  outline: none;
  box-shadow: none;
}

.btn_calandar::after {
  display: none;
}

.btn_calandar span {
  margin-left: 10px;
}

.btn_calandar {
  margin-left: 7px;
}

.badge_buy {
  color: #3c932d;
  background-color: #d6edd2;
  padding: 2px 12px;
  font-size: 13px;
  margin-right: 10px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 4px;
}

.badge_sell {
  color: #e13434;
  background-color: #ffc0c0;
  padding: 2px 12px;
  font-size: 13px;
  margin-right: 10px;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 4px;
}

.badge_complete {
  color: #3c932d;
  background-color: #d6edd2;
  font-size: 13px;
  padding: 2px 12px;
  font-weight: 500;
  border-radius: 4px;
}

.order_list_card {
  display: flex;
  flex-direction: row;
}

.order_list_card_left {
  width: 70%;
}

.order_list_card_right {
  width: 30%;
}

.btn_green_sml {
  color: #3c932d;
  background-color: #d6edd2;
  padding: 2px 15px;
  font-size: 14px;
}

.share_nm {
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.order_list_btm {
  font-size: 12px;
  color: #666;
  margin-right: 10px;
}

.badge_blue {
  color: #4074f9;
  border: solid 1px #4074f9;
  padding: 0px 5px;
  font-size: 11px;
}

.Avg_price {
  font-size: 14px;
}

.sep_line {
  border-bottom: solid 1px #ddd;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 0px;
  margin-right: 0;
}

.strategyTabs li a {
  background-color: #ededed;
}

.strategyTabs li {
  margin-right: 0px;
}

.sep_line.end {
  border: none;
}

.cursor_pointer {
  cursor: pointer;
}

.btn_kill_cancel {
  border: solid 1px #878787 !important;
}

.btn_kill_submit {
  background-color: #e0eaff !important;
  border: solid 1px #9bcefd !important;
}

.btn_kill_submit:hover {
  background-color: #4074f9 !important;
  transition: all 0.7s;
  color: #fff;
}

/* Basic Rules */
.switch input {
  display: none;
}

.switch {
  display: inline-block;
  width: 60px;
  height: 30px;
  margin: 8px;
  transform: translateY(50%);
  position: relative;
  overflow: visible;
  background-color: #fff;
  border-radius: 30px;
  margin-top: -15px;
}

.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  box-shadow: 0 0 0 2px #777, 0 0 4px #777;
  cursor: pointer;
  border: 4px solid transparent;
  overflow: hidden;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: #777;
  border-radius: 30px;
  transform: translateX(-30px);
  transition: 0.4s;
}

.switch input:checked + .slider:before {
  transform: translateX(30px);
  background: #4074f9;
}

.switch input:checked + .slider {
  box-shadow: 0 0 0 2px #4074f9, 0 0 2px #4074f9;
}

/* Style Flat */
.switch.flat .slider {
  box-shadow: none;
}

.switch.flat .slider:before {
  background: #fff;
}

.switch.flat input:checked + .slider:before {
  background: white;
}

.switch.flat input:checked + .slider {
  background: #4074f9;
}

.tradesetting_tab .nav.nav-tabs .nav-item .nav-link {
  position: relative;
  border: none !important;
}

.tradesetting_tab .nav.nav-tabs .nav-item .nav-link.active {
  background-color: transparent !important;
  border: none !important;
}

.tradesetting_tab .nav.nav-tabs .nav-item .nav-link.active::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #4074f9;
  bottom: 0;
  left: 0;
}

.tradesetting_tab .nav.nav-tabs .nav-item .nav-link:hover::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #4074f9;
  bottom: 0;
  left: 0;
}

.switch.button input:checked + .slider::before {
  background: #fff;
  content: '';
  transform: translateX(65px);
}

.switch.button input:checked + .slider {
  background: #4074f9;
}

.switch.button input:checked + .slider::after {
  content: 'On';
  color: #fff;
  text-align: center;
  width: 100%;
  height: 100%;
  display: block;
  line-height: 29px;
}

.switch.button::before {
  content: 'Off';
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 14px;
  height: 100%;
  line-height: 34px;
  border: 1px solid var(--color-border-2);
  border-radius: 99px;
}

.switch.button {
  width: 40%;
  height: 38px;
  margin-top: -18px;
  margin-left: 0;
}

.switch.button .slider {
  border-radius: 5px;
  box-shadow: 0 0 0 1px #ced4da, 0 0 1px #ced4da;
  width: 100%;
}

.switch.button .slider::before {
  position: absolute;
  content: '';
  width: 30px;
  height: 30px;
  background: #999;
  border-radius: 99px;
  transform: translateX(0px);
  transition: 0.4s;
  border: none;
  box-shadow: none;
}
.dark {
  .switch {
    &.button {
      background-color: var(--color-component-gray-2);
    }
  }
}
.btn_disconnect {
  background-color: #df4444;
  color: #fff !important;
  text-align: center;
  border-radius: 5px;
}

.btn_connect {
  background-color: #43a233;
  color: #fff !important;
  text-align: center;
  border-radius: 5px;
}

.broker_card {
  border: solid 1px #ddd;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

.broker_card h3 {
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.broker_card:hover {
  box-shadow: 0px 5px 20px 0px #ddd;
  transition: all 0.5s;
}

.ordr_lst_hd {
  position: absolute;
  left: 0;
  background-color: #ededed;
  padding: 10px 20px;
  border-radius: 10px 10px 0px 0px;
  border: solid 1px#7f7f7f;
  border-bottom: none;
}

.belowhead_mid_sec.strategy {
  align-items: center;
  justify-content: center;
  width: 75%;
}

.belowhead_lst_sec.strategy {
  width: 5%;
}

.card_head h4 {
  background-color: #eeeeee;
  padding: 4px;
  border: solid 1px #b6b6b6;
  border-radius: 6px 6px 0px 0px;
  border-bottom: none;
}

.empty_card h3 {
  font-size: 15px;
}

.empty_card {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 82vh;
  padding: 10px;
  width: 100%;
}

.empty_card button {
  background-color: #fff;
  border: solid 1px #dbdbdb;
  height: 40px;
  width: 40px;
  border-radius: 6px;
}

.empty_card p {
  color: #b2b2b2;
}

.empty_card h3 {
  color: #b2b2b2;
  font-weight: normal;
}

.card_head h4 {
  font-size: 15px;
  padding: 7px 15px;
  margin-bottom: 0;
  color: #aeafb1;
}

.btn_premium_access {
  width: auto !important;
  padding: 7px 15px;
  height: auto !important;
}

.btn_premium_access:hover {
  background-color: #4074f9;
  color: #fff;
  transition: all 0.7s;
}

.card_head .active {
  background-color: #e0eaff;
  border-color: #4074f9;
  color: #4074f9;
}

.empty_card button:hover {
  box-shadow: 0px 0px 30px 0px #b5cbf9;
  transition: all 0.7s;
}

.modal_para {
  line-height: 25px;
}

.report_card {
  background-color: #f6f6f6;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.sts_completed {
  background-color: #43a233;
  color: #fff;
  border-radius: 15px;
  padding: 2px 15px;
  font-size: 12px;
  margin-right: 10px;
}

.sts_expired {
  background-color: #db1212;
  color: #fff;
  border-radius: 15px;
  padding: 2px 15px;
  font-size: 12px;
  margin-right: 10px;
}

.sts_pending {
  background-color: #ff764b;
  color: #fff;
  border-radius: 15px;
  padding: 2px 15px;
  font-size: 12px;
  margin-right: 10px;
}

.sts_processing {
  background-color: #4074f9;
  color: #fff;
  border-radius: 15px;
  padding: 2px 15px;
  font-size: 12px;
  margin-right: 10px;
}

.sts_inactive {
  background-color: #cecece;
  color: #737373;
  border-radius: 15px;
  padding: 2px 15px;
  font-size: 12px;
  margin-right: 10px;
}

.card_body {
  height: 68vh;
  overflow: auto;
  background-color: #fff;
  padding: 10px;
}

.btn_view_more {
  background-color: #e0eaff !important;
  border: solid 1px #4074f9 !important;
  border-radius: 4px !important;
  padding: 4px 8px !important;
  color: #376aec !important;
  font-size: 13px !important;
  width: auto !important;
  height: auto !important;
}

.btn_view_more:hover {
  background-color: #4074f9 !important;
  color: #fff !important;
  transition: all 0.7s !important;
}

.btn_view_more:disabled {
  opacity: 0.5;
  pointer-events: none;
  transition: none !important;
}

.btn_print {
  background-color: transparent !important;
  border: solid 1px #4074f9 !important;
  padding: 0px !important;
  border-radius: 100% !important;
  height: 28px !important;
  width: 28px !important;
  margin-right: 7px !important;
}

.btn_delete {
  background-color: transparent !important;
  border: solid 1px #db1212 !important;
  padding: 0px !important;
  border-radius: 100% !important;
  height: 28px !important;
  width: 28px !important;
}

.btn_print:hover {
  background-color: #4074f9 !important;
}

.btn_print:hover svg path {
  stroke: #fff;
}
.strategy_lenght {
  max-width: 100px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.btn_print:disabled {
  opacity: 0.5;
  background-color: none;
}

.btn_delete:hover {
  background-color: #db1212 !important;
}

.btn_delete:disabled {
  opacity: 0.5;
  background-color: none;
}

.btn_delete:hover svg path {
  fill: #fff;
}

.btn_report_add {
  background-color: #fff;
  border: solid 1px #d3d3d3;
  padding: 0px 20px;
  border-radius: 10px 10px 0 0;
  font-size: 24px;
  border-bottom: none;
  color: #4074f9;
}

.btn_report_add button {
  background-color: transparent;
}

.btn_report_add button {
  border: none;
}

.btn_report_date {
  background-color: #fff;
  border: solid 1px #d3d3d3;
  padding: 0px 20px;
  border-radius: 10px 10px 0 0;
  font-size: 16px;
  border-bottom: none;
}

.btn_report_date span {
  margin-right: 15px;
  color: #9c9c9c;
}

.report_date {
  font-size: 13px;
  line-height: 22px;
}

.btn_calandar + div a {
  color: #666;
  font-size: 15px;
}

.btn_calandar + div {
  border-color: #efefef;
  box-shadow: 0px 4px 7px 2px #ddd;
}

.empty_card .invalid {
  width: auto !important;
  padding: 7px 15px !important;
  height: auto !important;
}

.empty_card .valid {
  width: auto !important;
  padding: 7px 15px !important;
  height: auto !important;
  background-color: #ff9364 !important;
  color: #fff;
}

.dnt_have_order {
  font-size: 16px;
  text-align: center;
  margin-top: 25px;
}

.btn_premium_access.process {
  position: relative;
  background-color: #ff9364;
  padding-right: 52px;
  color: #fff;
}

.btn_premium_access.process:hover {
  box-shadow: 0px 0px 30px 0px #ff9364;
}

.btn_premium_access .circle::after {
  background: rgba(255, 255, 255, 0.4);
}

.btn_premium_access .circle::before {
  background: rgba(255, 255, 255, 0.6);
  animation-delay: -0.5s;
}

.btn_premium_access .circle::after,
.btn_premium_access .circle::before {
  background-color: #fff;
}

.btn_premium_access .circle {
  background-color: #fff;
}

.ant-picker-dropdown {
  z-index: 9999;
}

// .ant-picker,
// .ant-input {
//   background-color: #fff !important;
// }

.report_card .slider::before {
  transform: translateX(-57px);
  transition: 0.4s;
}

.report_card .switch input:checked + .slider::before {
  transform: translateX(5px);
}

.report_card .switch {
  margin: 0px;
  position: relative;
  right: 15px;
  top: -15px;
}
.card_head.mobile {
  border-top: solid 1px #4074f9;
  margin-top: 0;
}
#root {
  padding-top: 10px;
}
.rm_spc {
  padding-left: 0;
  padding-right: 0;
}
.order_list.scrollCss.ss > div {
  background-color: #f6f6f6;
  border-radius: 10px;
  border: none;
  margin-bottom: 10px;
}
.order_list.scrollCss.ss .share_nm {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 15px;
}
@media only screen and (max-width: 576px) {
  .bck_sec {
    width: 15%;
  }

  .btn_alert {
    display: none;
  }

  .btn_alert {
    display: none;
  }

  .btn_calandar {
    font-size: 13px;
  }

  .brnd_nm_sec {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-left: 0;
  }

  .brnd_nm_sec {
    width: 4 0%;
  }

  .brnd_nm_sec.strategy {
    width: 85%;
  }

  .belowhead_mid_sec {
    align-items: start;
    justify-content: start;
    width: 100% !important;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: solid 1px #efefef;
    padding-left: 5px;
    padding-right: 5px;
  }

  .belowhead_mid_sec ul {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .belowhead_mid_sec ul li {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .belowhead_mid_sec ul li span:nth-child(1) {
    float: left;
  }

  .belowhead_mid_sec ul li span:nth-child(2) {
    float: right;
  }

  .back_btn {
    height: 30px;
    width: 30px;
  }

  .brnd_nm {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 2px;
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .btn_brnd_sts_active {
    border-radius: 4px;
    padding: 2px 15px;
    line-height: 20px;
    margin-bottom: 15px;
  }

  .belowhead_lst_sec.strategy {
    display: none;
  }

  .report_date {
    font-size: 12px;
  }

  .sts_completed {
    font-size: 11px;
    margin-right: 5px;
  }

  .sts_expired {
    margin-right: 5px;
    font-size: 11px;
  }

  .sts_pending {
    margin-right: 5px;
    font-size: 11px;
  }

  .btn_print {
    height: 30px;
    width: 30px;
  }

  .btn_print svg {
    height: 15px;
    width: 15px;
  }

  .btn_delete {
    height: 30px;
    width: 30px;
  }

  .btn_delete svg {
    height: 15px;
    width: 15px;
  }

  .report_card h3 {
    font-size: 14px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .card_body {
    background-color: #fff;
    padding: 10px;
    height: 400px;
    overflow-y: scroll;
  }

  .card_head {
    margin-top: 15px;
  }

  .empty_card {
    min-height: 170px;
  }

  .btn_view_more {
    font-size: 12px !important;
  }

  .belowhead ul li span {
    font-size: 14px;
  }

  .belowhead ul li span {
    font-size: 14px;
  }

  .risk_ico {
    position: relative;
    top: 0px;
    float: right;
  }
  .grow_ico {
    position: relative;
    top: 0px;
    float: right;
  }

  .modal-header h4 {
    font-size: 18px !important;
  }

  .tab-content .form-group {
    margin-bottom: 5px;
  }

  .tab-content .form-group label {
    margin-bottom: 5px !important;
  }

  .switch.button {
    width: 100%;
    height: 38px;
    margin-top: -18px;
    margin-left: 0;
  }

  .switch.button input:checked + .slider::before {
    transform: translateX(147px);
  }

  .tradesetting_tab .nav.nav-tabs .nav-item .nav-link {
    position: relative;
    border: none !important;
    padding: 8px 17px;
    font-size: 18px !important;
    text-align: center !important;
  }

  .tradesetting_tab .nav-tabs li {
    width: 50%;
  }

  .empty_card button {
    font-size: 15px;
  }

  .empty_card h3 {
    font-size: 14px !important;
  }

  .empty_card h3 {
    color: #b2b2b2;
    font-weight: normal;
    font-size: 14px !important;
  }

  .belowhead.col {
    padding-right: 15px;
    padding-left: 15px;
  }

  .updt_info ul {
    border: solid 1px #efefef;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #fff;
  }

  .updt_info ul li:nth-child(1) {
    padding-right: 10px !important;
    width: 100%;
    text-align: left;
  }

  .updt_info ul li:nth-child(2) {
    width: 100%;
    text-align: left;
  }

  .updt_info ul li:nth-child(3) {
    width: 100%;
    text-align: left;
  }

  .updt_info ul li:nth-child(4) {
    width: 100%;
    text-align: left;
  }

  .updt_info ul li:nth-child(5) {
    width: 100%;
    text-align: left;
  }

  .updt_info ul li:nth-last-child(1) {
    border: none;
    display: block;
    width: 100%;
  }

  .updt_info ul li {
    list-style: none;
    padding: 5px 10px 5px 10px;
    border-right: none;
    border-bottom: solid 1px #efefef;
    font-size: 14px;
    position: relative;
    font-weight: 400;
  }
  .indicator-fld {
    position: absolute !important;
    top: 45px;
    font-size: 14px;
    left: 30px !important;
  }
  .circle,
  .indicator {
    top: 2px;
  }
  .css-oqchhx-MuiContainer-root {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .TVChartContainer {
    height: calc(100vh - 0px) !important;
    margin-top: 50px;
  }
  .ordr_lst_hd {
    font-size: 15px !important;
    min-width: 110px;
    text-align: center;
    height: auto;
    padding: 7px;
  }
  .btn_calandar + div a {
    font-size: 14px;
  }
  .updt_info ul li:nth-child(1) span {
    display: inline-block;
  }
  .updt_info ul li span:nth-child(1) {
    display: inline-block;
    // float: right;xz
  }
  .updt_info ul li span:nth-child(2) {
    display: inline-block;
    float: right;
    font-weight: normal !important;
  }

  .updt_info ul li:nth-child(1) .circle {
    top: 35%;
  }

  .chrt_data {
    min-height: 200px;
    background-color: #fff;
    padding-top: 40px;
  }
  .pnldata_aside.d-flex {
    position: absolute;
    top: 45px;
    left: 20px;
  }
  .aside_data {
    font-size: 14px;
    padding: 0px 10px;
    border: solid 1px #ddd;
    border-radius: 4px;
    padding-left: 8px;
  }
  .rem_space {
    padding-left: 0px;
    padding-right: 0px;
  }

  .rem_left {
    padding-left: 0px;
  }

  .rem_right {
    padding-right: 0px;
  }

  .btn_info {
    display: none;
  }

  .strategyTabs.mobile li a {
    font-size: 14px !important;
    padding: 5px 5px !important;
    border-radius: 7px 7px 0 0 !important;
  }

  .card_head.mobile {
    background-color: #fff;
    padding-top: 30px;
  }

  .belowhead_lst_sec {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: end;
    position: absolute;
    right: 0;
    top: 25px;
    padding-left: 0px;
  }

  .belowhead_lst_sec button svg {
    height: 15px;
    width: 15px;
  }

  .belowhead_lst_sec button {
    background-color: #e0eaff;
    border: none;
    height: 28px;
    width: 28px;
    border-radius: 100%;
    margin-right: 1px;
    margin-left: 1px;
  }

  .order_list {
    background-color: #fff;
    min-height: 300px;
    padding: 5px;
    height: 300px;
  }
  span.order_list_btm.dt {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .share_nm {
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .badge_buy {
    color: #3c932d;
    background-color: #d6edd2;
    padding: 2px 10px;
    font-size: 12px;
    margin-right: 10px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 4px;
    line-height: 20px;
  }
  .badge_complete {
    color: #3c932d;
    background-color: #d6edd2;
    font-size: 12px;
    padding: 2px 12px;
    font-weight: 500;
    border-radius: 4px;
  }
  .order_list_bx {
    height: 100%;
    overflow: auto;
    background-color: #fff;
    padding: 5px;
  }
  .updt_info {
    margin-top: 10px;
  }

  .strategyTabs li a {
    padding: 5px 5px !important;
    font-size: 15px !important;
  }

  .modal-footer > .form-check {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .modal-footer .d-flex {
    width: 100%;
    justify-content: space-between;
    margin-top: 25px;
  }

  .modal-footer .btn_kill_cancel {
    display: flex;
  }

  .modal-footer .btn_kill_submit {
    display: flex;
  }

  .broker_card {
    margin-bottom: 20px;
  }

  #Broker h5 {
    font-size: 14px !important;
    padding-left: 0 !important;
  }

  #Broker h6 {
    font-size: 14px !important;
    padding-right: 0 !important;
  }

  .btn_disconnect {
    font-size: 14px;
    padding: 5px !important;
  }

  .btn_connect {
    font-size: 14px;
    padding: 5px !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .bck_sec {
    width: 15%;
  }

  .brnd_nm_sec {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }

  .brnd_nm_sec {
    width: 85%;
  }

  .belowhead_mid_sec {
    align-items: start;
    justify-content: start;
    width: 100% !important;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .belowhead_mid_sec ul {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .belowhead_mid_sec ul li {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .bgLight {
    margin-left: 0;
    margin-right: 0;
  }
  .belowhead_mid_sec ul li span:nth-child(1) {
    float: left;
  }

  .belowhead_mid_sec ul li span:nth-child(2) {
    float: right;
  }

  .back_btn {
    height: 30px;
    width: 30px;
  }

  .brnd_nm {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 2px;
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .btn_brnd_sts_active {
    border-radius: 4px;
    padding: 2px 15px;
    line-height: 20px;
    margin-bottom: 15px;
  }

  .belowhead_lst_sec.strategy {
    display: none;
  }

  .report_date {
    font-size: 12px;
  }

  .sts_completed {
    font-size: 11px;
    margin-right: 5px;
  }

  .sts_expired {
    margin-right: 5px;
    font-size: 11px;
  }

  .sts_pending {
    margin-right: 5px;
    font-size: 11px;
  }

  .btn_print {
    height: 30px;
    width: 30px;
  }

  .btn_print svg {
    height: 15px;
    width: 15px;
  }

  .btn_delete {
    height: 30px;
    width: 30px;
  }

  .btn_delete svg {
    height: 15px;
    width: 15px;
  }

  .report_card h3 {
    font-size: 14px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .card_body {
    background-color: #fff;
    padding: 10px;
    height: 300px;
    overflow-y: scroll;
  }

  .card_head {
    margin-top: 15px;
  }

  .empty_card {
    min-height: 170px;
  }

  .btn_view_more {
    font-size: 12px;
  }

  .belowhead ul li span {
    font-size: 14px;
  }
  .risk_ico {
    float: right;
  }
  .grow_ico {
    float: right;
  }
  .belowhead ul li span {
    font-size: 14px;
  }

  .modal-header h4 {
    font-size: 18px !important;
  }

  .tab-content .form-group {
    margin-bottom: 5px;
  }

  .tab-content .form-group label {
    margin-bottom: 5px !important;
  }

  .switch.button {
    width: 100%;
    height: 38px;
    margin-top: -18px;
    margin-left: 0;
  }

  .switch.button input:checked + .slider::before {
    transform: translateX(125px);
  }

  .tradesetting_tab .nav.nav-tabs .nav-item .nav-link {
    position: relative;
    border: none !important;
    padding: 8px 17px;
    font-size: 18px !important;
    text-align: center !important;
  }

  .tradesetting_tab .nav-tabs li {
    width: 50%;
  }

  .modal-footer {
    margin-top: 20px !important;
  }

  .empty_card button {
    font-size: 15px;
  }

  .empty_card h3 {
    font-size: 14px !important;
  }

  .empty_card h3 {
    color: #b2b2b2;
    font-weight: normal;
    font-size: 14px !important;
  }

  .belowhead.col {
    padding-right: 0;
    padding-left: 0;
  }

  .updt_info ul {
    border: solid 1px #d2d2d2;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  .updt_info ul li:nth-child(1) {
    padding-right: 10px !important;
    width: 100%;
    text-align: left;
  }
  .updt_info ul li:nth-child(2) {
    width: 100%;
    text-align: left;
  }

  .updt_info ul li:nth-child(3) {
    width: 100%;
    text-align: left;
  }

  .updt_info ul li:nth-child(4) {
    width: 100%;
    text-align: left;
  }

  .updt_info ul li:nth-child(5) {
    width: 100%;
    text-align: left;
  }

  .updt_info ul li {
    list-style: none;
    padding: 5px 10px 5px 10px;
    border-right: none;
    border-bottom: solid 1px #efefef;
    font-size: 14px;
    position: relative;
    font-weight: 400;
  }

  .updt_info ul li:nth-child(1) .circle {
    top: 35%;
  }
  .updt_info ul li span:nth-child(1) {
    display: inline-block;
    float: left;
  }
  .updt_info ul li span:nth-child(2) {
    display: inline-block;
    float: right;
    font-weight: normal !important;
  }
  .chrt_data {
    min-height: 200px;
    background-color: #fff;
  }
  .updt_info ul {
    border: solid 1px #efefef;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #fff;
  }
  .rem_space {
    padding-left: 0px;
    padding-right: 0px;
  }

  .rem_left {
    padding-left: 15px;
  }

  .rem_right {
    padding-right: 15px;
  }

  .belowhead_lst_sec {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: end;
    position: absolute;
    right: 0px;
    top: 15px;
    padding-left: 0px;
  }

  .belowhead_lst_sec button svg {
    height: 18px;
    width: 18px;
  }

  .belowhead_lst_sec button {
    background-color: #e0eaff;
    border: none;
    height: 36px;
    width: 36px;
    border-radius: 100%;
    margin-right: 2px;
    margin-left: 2px;
  }

  .order_list {
    background-color: #fff;
    padding: 5px;
  }

  .updt_info {
    margin-top: 10px;
  }

  .strategyTabs li a {
    padding: 5px 5px !important;
  }

  .modal-footer > .form-check {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .modal-footer .d-flex {
    width: 100%;
    justify-content: space-between;
    margin-top: 25px;
  }

  .modal-footer .btn_kill_cancel {
    display: flex;
  }

  .modal-footer .btn_kill_submit {
    display: flex;
  }

  .broker_card {
    margin-bottom: 20px;
  }

  #Broker h5 {
    font-size: 14px !important;
    padding-left: 0 !important;
  }

  #Broker h6 {
    font-size: 14px !important;
    padding-right: 0 !important;
  }

  .btn_disconnect {
    font-size: 15px;
    padding: 10px !important;
  }

  .btn_connect {
    font-size: 15px;
    padding: 10px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .bck_sec {
    width: 8%;
  }

  .brnd_nm_sec {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }

  .brnd_nm_sec {
    width: 87%;
  }

  .belowhead_mid_sec {
    align-items: start;
    justify-content: start;
    width: 100% !important;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: solid 1px #efefef;
  }
  .bgLight {
    margin-left: 0;
    margin-right: 0;
  }

  .belowhead_mid_sec ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .belowhead_mid_sec ul li {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .belowhead_mid_sec ul li span:nth-child(1) {
    float: left;
  }

  .belowhead_mid_sec ul li span:nth-child(2) {
    float: right;
  }

  .back_btn {
    height: 30px;
    width: 30px;
  }

  .brnd_nm {
    font-size: 18px;
    margin-bottom: 2px;
    margin-top: 0px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .btn_brnd_sts_active {
    border-radius: 4px;
    line-height: 20px;
    margin-bottom: 15px;
    padding: 2px 15px;
  }

  .belowhead_lst_sec.strategy {
    display: block;
    width: 7%;
  }

  .report_date {
    font-size: 12px;
  }

  .sts_completed {
    font-size: 11px;
    margin-right: 5px;
  }

  .sts_expired {
    margin-right: 5px;
    font-size: 11px;
  }

  .sts_pending {
    margin-right: 5px;
    font-size: 11px;
  }

  .btn_print {
    height: 30px;
    width: 30px;
  }

  .btn_print svg {
    height: 15px;
    width: 15px;
  }

  .btn_delete {
    height: 30px;
    width: 30px;
  }

  .btn_delete svg {
    height: 15px;
    width: 15px;
  }

  .report_card h3 {
    font-size: 14px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .card_body {
    background-color: #fff;
    padding: 10px;
    height: 300px;
    overflow-y: scroll;
  }

  .card_head {
    margin-top: 15px;
  }

  .empty_card {
    min-height: 170px;
  }

  .btn_view_more {
    font-size: 12px;
  }

  .belowhead ul li span {
    font-size: 14px;
  }

  .belowhead ul li span {
    font-size: 13px;
  }

  .modal-header h4 {
    font-size: 18px !important;
  }

  .tab-content .form-group {
    margin-bottom: 5px;
  }

  .tab-content .form-group label {
    margin-bottom: 5px !important;
  }

  .switch.button {
    width: 100%;
    height: 38px;
    margin-top: -18px;
    margin-left: 0;
  }

  .switch.button input:checked + .slider::before {
    transform: translateX(125px);
  }

  .tradesetting_tab .nav.nav-tabs .nav-item .nav-link {
    position: relative;
    border: none !important;
    padding: 8px 17px;
    font-size: 18px !important;
    text-align: center !important;
  }

  .tradesetting_tab .nav-tabs li {
    width: 50%;
  }

  .modal-footer {
    margin-top: 20px !important;
  }

  .empty_card button {
    font-size: 15px;
  }

  .empty_card h3 {
    font-size: 14px !important;
  }

  .empty_card h3 {
    color: #b2b2b2;
    font-weight: normal;
    font-size: 14px !important;
  }

  .belowhead.col {
    padding-right: 0;
    padding-left: 0;
  }

  .updt_info ul {
    border: solid 1px #efefef;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #fff;
  }
  .updt_info ul li span:nth-child(2) {
    display: inline-block;
    float: right;
    font-weight: normal !important;
  }
  .updt_info ul li span:nth-child(1) {
    display: inline-block;
    float: left;
  }

  .updt_info ul li:nth-child(1) {
    padding-right: 10px !important;
    width: 100%;
  }

  .updt_info ul li:nth-child(2) {
    width: 100%;
    text-align: left;
  }

  .updt_info ul li:nth-child(3) {
    width: 100%;
    text-align: left;
  }

  .updt_info ul li:nth-child(4) {
    width: 100%;
    text-align: left;
  }

  .updt_info ul li:nth-child(5) {
    width: 100%;
    text-align: left;
  }

  .updt_info ul li {
    list-style: none;
    padding: 5px 10px 5px 10px;
    border-right: none;
    border-bottom: solid 1px #efefef;
    font-size: 14px;
    position: relative;
    font-weight: 400;
    height: 100%;
  }

  .updt_info ul li:nth-child(1) span {
    display: block;
  }

  .updt_info ul li:nth-child(1) .circle {
    top: 15%;
  }

  .chrt_data {
    min-height: 200px;
    background-color: #fff;
  }

  .rem_space {
    padding-left: 0px;
    padding-right: 0px;
  }

  .rem_left {
    padding-left: 0px;
  }

  .rem_right {
    padding-right: 0px;
  }

  .belowhead_lst_sec {
    width: 30%;
    display: flex;
    align-items: start;
    justify-content: end;
    position: absolute;
    right: 0;
    top: 15px;
    padding-left: 0px;
    height: 64px;
    padding-top: 7px;
  }
  .ordr_lst_hd {
    position: absolute;
    left: 0;
    background-color: #ededed;
    padding: 8px 20px;
    border-radius: 10px 10px 0px 0px;
    border: solid 1px #7f7f7f;
    border-bottom: none;
    font-size: 16px !important;
  }
  .order_list_bx {
    height: 35vh;
    overflow: auto;
    background-color: #fff;
    padding: 5px;
  }
  .belowhead_lst_sec button svg {
    height: 18px;
    width: 18px;
  }

  .belowhead_lst_sec button {
    background-color: #e0eaff;
    border: none;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    margin-right: 2px;
    margin-left: 2px;
  }

  .order_list {
    background-color: #fff;
    min-height: 500px;
    padding: 5px;
  }

  .updt_info {
    margin-top: 10px;
  }

  .strategyTabs li a {
    padding: 5px 5px !important;
  }

  .modal-footer > .form-check {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .modal-footer .d-flex {
    width: 100%;
    justify-content: space-between;
    margin-top: 25px;
  }

  .modal-footer .btn_kill_cancel {
    display: flex;
  }

  .modal-footer .btn_kill_submit {
    display: flex;
  }

  .broker_card {
    margin-bottom: 20px;
  }

  #Broker h5 {
    font-size: 14px !important;
    padding-left: 0 !important;
  }

  #Broker h6 {
    font-size: 14px !important;
    padding-right: 0 !important;
  }

  .btn_disconnect {
    font-size: 14px;
    padding: 5px !important;
  }

  .btn_connect {
    font-size: 14px;
    padding: 5px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .bck_sec {
    width: 10%;
  }
  .bgLight {
    margin-left: 0;
    margin-right: 0;
  }
  .brnd_nm_sec {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }

  .brnd_nm_sec {
    width: 50%;
  }

  .belowhead_mid_sec {
    align-items: start;
    justify-content: start;
    width: 100% !important;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: solid 1px #efefef;
  }
  .belowhead_mid_sec ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .belowhead_mid_sec ul li {
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    padding: 2px 10px;
  }

  .belowhead_mid_sec ul li span:nth-child(1) {
    float: none;
  }

  .belowhead_mid_sec ul li span:nth-child(2) {
    float: none;
    display: inline-block;
  }

  .back_btn {
    height: 30px;
    width: 30px;
  }

  .brnd_nm {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 2px;
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .btn_brnd_sts_active {
    border-radius: 4px;
    padding: 2px 15px;
    line-height: 20px;
    margin-bottom: 15px;
  }

  .belowhead_lst_sec.strategy {
    display: block;
    width: 10%;
  }

  .report_date {
    font-size: 12px;
  }

  .sts_completed {
    font-size: 11px;
    margin-right: 5px;
  }

  .sts_expired {
    margin-right: 5px;
    font-size: 11px;
  }

  .sts_pending {
    margin-right: 5px;
    font-size: 11px;
  }

  .btn_print {
    height: 30px;
    width: 30px;
  }

  .btn_print svg {
    height: 15px;
    width: 15px;
  }

  .btn_delete {
    height: 30px;
    width: 30px;
  }

  .btn_delete svg {
    height: 15px;
    width: 15px;
  }

  .report_card h3 {
    font-size: 14px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .card_body {
    background-color: #fff;
    padding: 10px;
    height: auto;
    overflow-y: scroll;
  }

  .card_head {
    margin-top: 15px;
  }

  .empty_card {
    min-height: 300px;
  }

  .empty_card p {
    color: #b2b2b2;
    font-size: 14px;
  }

  .btn_view_more {
    font-size: 12px;
  }

  .belowhead ul li span {
    font-size: 14px;
  }

  .belowhead ul li span {
    font-size: 14px;
  }

  .modal-header h4 {
    font-size: 18px !important;
  }

  .tab-content .form-group {
    margin-bottom: 5px;
  }

  .tab-content .form-group label {
    margin-bottom: 5px !important;
  }

  .switch.button {
    width: 100%;
    height: 38px;
    margin-top: -18px;
    margin-left: 0;
  }

  .switch.button input:checked + .slider::before {
    transform: translateX(148px);
  }

  .tradesetting_tab .nav.nav-tabs .nav-item .nav-link {
    position: relative;
    border: none !important;
    padding: 8px 17px;
    font-size: 18px !important;
    text-align: center !important;
  }

  .tradesetting_tab .nav-tabs li {
    width: 50%;
  }

  .modal-footer {
    margin-top: 20px !important;
  }

  .empty_card button {
    font-size: 15px;
  }

  .empty_card h3 {
    font-size: 14px !important;
  }

  .empty_card h3 {
    color: #b2b2b2;
    font-weight: normal;
    font-size: 14px !important;
  }

  .belowhead.col {
    padding-right: 0;
    padding-left: 0;
  }

  .updt_info ul {
    border: solid 1px #efefef;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #fff;
  }

  .updt_info ul li:nth-child(1) {
    padding-right: 10px !important;
    width: 100%;
    text-align: left;
  }

  .updt_info ul li:nth-child(2) {
    width: 100%;
    text-align: left;
  }

  .updt_info ul li:nth-child(3) {
    width: 100%;
    text-align: left;
  }

  .updt_info ul li:nth-child(4) {
    width: 100%;
    text-align: left;
  }

  .updt_info ul li:nth-child(5) {
    width: 100%;
    text-align: left;
  }

  .updt_info ul li {
    list-style: none;
    padding: 5px 10px 5px 10px;
    border-right: none;
    border-bottom: solid 1px #efefef;
    font-size: 14px;
    position: relative;
    font-weight: 400;
  }

  .updt_info ul li:nth-child(1) span {
    display: inline-block;
  }
  .updt_info ul li span:nth-child(1) {
    display: inline-block;
    float: left;
  }
  .updt_info ul li span:nth-child(2) {
    display: inline-block;
    float: right;
    font-weight: normal !important;
  }
  .updt_info ul li:nth-child(1) .circle {
    top: 15%;
  }

  .chrt_data {
    min-height: 600px;
    background-color: #fff;
  }

  .rem_space {
    padding-left: 10px;
    padding-right: 10px;
  }

  .btn_kill svg {
    height: 18px;
    width: 18px;
  }

  .rem_left {
    padding-left: 15px;
  }

  .rem_right {
    padding-right: 15px;
  }

  .belowhead_lst_sec {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: end;
    position: absolute;
    right: 0px;
    top: -1px;
    padding-left: 0px;
    height: 80px;
  }

  .belowhead_lst_sec button svg {
    height: 18px;
    width: 18px;
  }

  .belowhead_lst_sec button {
    background-color: #e0eaff;
    border: none;
    height: 36px;
    width: 36px;
    border-radius: 100%;
    margin-right: 2px;
    margin-left: 2px;
  }

  .belowhead_lst_sec.strategy .btn_info {
    position: absolute;
    top: 22px;
    left: 15px;
  }

  .order_list {
    background-color: #fff;
    padding: 5px;
  }

  .updt_info {
    margin-top: 10px;
  }

  .strategyTabs li a {
    padding: 5px 5px !important;
  }

  .modal-footer > .form-check {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .modal-footer .d-flex {
    width: 100%;
    justify-content: space-between;
    margin-top: 25px;
  }

  .modal-footer .btn_kill_cancel {
    display: flex;
  }

  .modal-footer .btn_kill_submit {
    display: flex;
  }

  .broker_card {
    margin-bottom: 20px;
  }

  #Broker h5 {
    font-size: 14px !important;
    padding-left: 0 !important;
  }

  #Broker h6 {
    font-size: 14px !important;
    padding-right: 0 !important;
  }

  .btn_disconnect {
    font-size: 14px;
    padding: 10px !important;
  }

  .btn_connect {
    font-size: 14px;
    padding: 10px !important;
  }

  .order_list_head .btn_kill {
    padding: 7px 10px;
    border-radius: 7px 7px 0 0px;
  }

  .order_list_head button {
    background-color: #c8c8c8;
    border: solid 1px;
    border-color: #d4d4d4;
    padding: 7px 10px;
    border-radius: 10px 10px 0 0px;
    font-size: 15px;
    border-bottom: none;
  }

  .order_list_head h3 {
    font-size: 16px;
    margin-bottom: 0;
  }
  .ordr_lst_hd {
    position: absolute;
    left: 0;
    background-color: #ededed;
    padding: 8px 15px;
    border-radius: 10px 10px 0px 0px;
    border: solid 1px #7f7f7f;
    border-bottom: none;
  }
  span.order_list_btm.dt {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .kill_switch {
    background-color: #db1212;
  }

  .kill_switch button {
    color: white;
  }
}

@media only screen and (min-width: 1000px) {
  .css-1vqlns {
    display: none !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1680px) {
  .brnd_nm_sec {
    width: 10%;
    padding-right: 0;
  }
  .float-right.btn_view_more {
    width: 85px !important;
  }
  .belowhead ul li span {
    font-size: 14px;
    line-height: 25px;
  }
  .belowhead_mid_sec.strategy {
    width: 80%;
  }
  span.order_list_btm.dt {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .belowhead_lst_sec button {
    height: 32px;
    width: 32px;
    border-radius: 100%;
    margin-right: 1px;
    margin-left: 1px;
  }
  .sts_completed {
    line-height: 16px;
  }
  .sts_processing {
    line-height: 16px;
  }
  .ordr_lst_hd {
    position: absolute;
    left: 0;
    background-color: #ededed;
    padding: 8px 15px;
    border-radius: 10px 10px 0px 0px;
    border: solid 1px #7f7f7f;
    border-bottom: none;
  }
  .belowhead_mid_sec.strategy {
    ul {
      li {
        position: relative;
        &:nth-child(1) {
          width: 30%;
        }
        &:nth-child(2) {
          width: 20%;
        }
        &:nth-child(3) {
          width: 20%;
        }
        &:nth-child(4) {
          width: 10%;
        }
        &:nth-child(5) {
          width: 10%;
        }
        .risk_ico,
        .grow_ico {
          right: 0;
          position: absolute;
        }
        span {
          display: inline-block !important;
        }
      }
    }
  }

  .belowhead ul li {
    list-style: none;
    display: inline-block;
    border: solid 1px #ddd;
    margin-right: 2px;
    margin-left: 2px;
    padding: 2px 7px;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .belowhead_mid_sec {
    width: 70%;
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: 400;

    ul {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .belowhead_lst_sec {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .updt_info ul li {
    padding: 5px;
    font-size: 14px;
  }

  .updt_info ul li span.circle {
    top: 15px;
  }

  .updt_info ul li span.indicator {
    top: 15px;
  }

  .updt_info ul li:nth-child(1) {
    text-align: center;
  }

  .updt_info ul li:nth-child(6) {
    width: 18%;
    text-align: center;
  }
  .order_list_head h3 {
    font-size: 14px;
    margin-bottom: 0;
  }
  .strategyTabs li a {
    font-size: 14px !important;
    padding: 7px 15px !important;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1500px) {
  .brnd_nm {
    font-size: 15px;
  }
  .TVChartContainer {
    height: 96vh !important;
  }
  .order_list_bx {
    height: 75vh;
  }
  .btn_brnd_sts_active {
    font-size: 11px;
    border-radius: 4px;
    padding: 2px 10px;
    line-height: 17px;
  }
  .btn_report_add {
    font-size: 18px;
  }
  .card_head h4 {
    font-size: 14px;
    padding: 7px 15px;
    margin-bottom: 0;
    color: #aeafb1;
    line-height: 16px;
  }
  .card_body {
    height: 66vh;
  }
  .report_date {
    font-size: 13px;
  }
  .btn_view_more {
    padding: 3px 7px !important;
    color: #376aec !important;
    font-size: 12px !important;
  }
}
