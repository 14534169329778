.loginSection {
  .logoOverlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: rgba(77, 77, 77, 0.84);
    mix-blend-mode: multiply;
    width: 38vw;
    height: 100vh;
    z-index: 12;
    img {
      position: absolute;
      top: 50%;
      transform: translate(0px, -50%);
      width: 25vw;
      left: 9vw;
    }
  }
  .loginPage {
    padding: 30px;
    background-color: $white;
    box-shadow: 7px 7px 27px rgb(0, 0, 0, 0.05);
    width: 85vw;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0px, -50%);

    @include responsivemax(991) {
      height: calc(100vh - 50px);
    }

    .ndHl {
      color: $primary_color;
      font-size: 12px;
    }

    h1 {
      font-family: $abhaya;
      font-size: 2.5vw;
      line-height: 64px;
      font-weight: 700;
      color: $darkGray;
      @include responsivemax(991) {
        font-size: 3vw;
      }
    }

    .adminLogin {
      color: $secondary_color;
      border: 1px solid $secondary_color;
      padding: 2px 10px;
      font-size: 12px;
      border-radius: 5px;
      font-weight: 500;
    }
  }

  .mobile_logo {
    width: 100%;
    max-width: 250px;
    margin-top: 50px;
  }

  .forgotPasswordResponse {
    color: $darkGray;
    font-size: 18px;
  }

  .forgotForm {
    padding-top: 8vh;
  }

  .resetPassword {
    padding-top: 10vh;
  }
}

.loginSection {
  .logoOverlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: rgba(77, 77, 77, 0.84);
    mix-blend-mode: multiply;
    width: 38vw;
    height: 100vh;
    z-index: 12;
    img {
      position: absolute;
      top: 50%;
      transform: translate(0px, -50%);
      width: 25vw;
      left: 9vw;
    }
  }
  .loginPage {
    padding: 30px;
    background-color: $white;
    box-shadow: 7px 7px 27px rgb(0, 0, 0, 0.05);
    width: 85vw;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0px, -50%);

    @include responsivemax(991) {
      height: calc(100vh - 50px);
    }

    .ndHl {
      color: $primary_color;
      font-size: 12px;
    }

    h1 {
      font-family: $abhaya;
      font-size: 2.5vw;
      line-height: 64px;
      font-weight: 700;
      color: $darkGray;
      @include responsivemax(991) {
        font-size: 3vw;
      }
    }

    .adminLogin {
      color: $secondary_color;
      border: 1px solid $secondary_color;
      padding: 2px 10px;
      font-size: 12px;
      border-radius: 5px;
      font-weight: 500;
    }
  }

  .mobile_logo {
    width: 100%;
    max-width: 250px;
    margin-top: 50px;
  }

  .forgotPasswordResponse {
    color: $darkGray;
    font-size: 18px;
  }

  .forgotForm {
    padding-top: 8vh;
  }

  .resetPassword {
    padding-top: 10vh;
  }
}
.eyeIcon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  cursor: pointer;
}
