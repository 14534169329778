.scrollCss,
.card_table .ant-table-body,
.ant-drawer-body,
.ant-table-content,
.ant-table .ant-table-body,
.ant-table .ant-table-content,
.ql-editor {
  touch-action: manipulation;
  // overflow: auto!important;
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    cursor: pointer !important;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(2, 132, 199, 0.3) !important;
    border-radius: 10px !important;
    // margin-top: 5px;
    // margin-bottom: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary_color !important;
    // outline: 1px solid rgba(112, 128, 144, 0.212);
    border-radius: 10px !important;
  }
  &.scrollHide {
    &::-webkit-scrollbar {
      opacity: 0 !important;
      border: none;
      background-color: transparent !important;
    }
    &::-webkit-scrollbar {
      width: 0px !important;
      height: 0px !important;
      cursor: pointer !important;
    }

    &::-webkit-scrollbar-track {
      opacity: 0 !important;
      border: none;
      box-shadow: none !important;
      background-color: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
      opacity: 0 !important;
      border: none;
      background-color: transparent !important;
    }
  }
}
.custm_modal {
  .ant-modal-close {
    width: unset !important;
    height: unset !important;
    &:hover {
      background-color: transparent !important;
    }
  }
}
.ant-table-placeholder {
  .ant-table-cell {
    border-bottom: 0px !important;
  }
}
.darkInputPassword,
.darkInputAntd {
  &.ant-select,
  &.ant-select-selection-item {
    font-family: 'Poppins', sans-serif !important;
  }
  font-family: 'Poppins', sans-serif !important;
  &:disabled {
    color: #cccccc !important;
  }

  // &.ant-input-number-disabled {
  //   background-color: rgb(68 64 60) !important;
  //   &:hover {
  //     background-color: rgb(68 64 60) !important;
  //   }
  // }

  .ant-input-number-handler-wrap {
    display: none !important;
  }
  // .ant-input-number-input-wrap {
  //   .ant-input-number-input {
  //     color: $white !important;

  //     &::placeholder {
  //       color: #818181 !important;
  //     }
  //   }
  // }
}
.dark {
  .darkInputPassword {
    .ant-input {
      color: $white;
    }
  }
}
.darkInputPassword {
  .ant-input {
    background-color: transparent;
    color: #0a0a0a;
    &::placeholder {
      color: rgb(148 163 184);
    }
  }
}
:where(
    .css-dev-only-do-not-override-1ae8k9u
  ).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
  background: unset !important;
}
.antd-popover {
  .ant-popover-inner {
    padding: 0 !important;
  }
}
// ===================================

.dark {
  /* Dark Theme Table */
  .ant-picker {
    background-color: var(--color-component-gray-2) !important;
    color: rgb(var(--color-text-primary)) !important;
    border-color: var(--color-component-gray-1) !important;
    &.dark-ant-picker {
      background-color: rgb(
        var(--color-gray-100) / var(--tw-bg-opacity)
      ) !important;
      color: rgb(var(--color-text-primary)) !important;
      border-color: rgb(
        var(--color-gray-100) / var(--tw-bg-opacity)
      ) !important;
    }
  }
  .dataTables,
  .card_table {
    .ant-table {
      // background-color: $black;
      // background-color: var(--color-component-gray-1);
      background-color: transparent;
      overflow: hidden;
      // .ant-table-content {
      .ant-table-thead {
        .ant-table-cell {
          color: rgb(var(--color-gray-4));
          .text-gray-500,
          .text-neutral-800 {
            color: rgb(var(--color-gray-4));
          }
          border-color: var(--color-border-1);
          background-color: var(--color-component-gray-1);

          &::before {
            background-color: var(--color-border-2) !important;
          }
        }
        .ant-table-column-sorters,
        .ant-dropdown-trigger {
          .anticon {
            color: rgb(var(--color-gray-4));
            &.active {
              color: rgb(var(--color-blue-2));
            }
          }

          &.active {
            .anticon {
              color: rgb(var(--color-blue-2));
            }
          }
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          color: $white;
          .text-gray-500,
          .text-gray-700,
          .text-neutral-900,
          .text-neutral-800,
          .text-neutral-800 {
            color: $white;
          }
          .text-sky-60 {
            --tw-text-opacity: 1;
            color: rgb(var(--color-blue-2) / var(--tw-text-opacity));
          }
          .hover\:text-sky-600:hover {
            --tw-text-opacity: 1;
            color: rgb(var(--color-blue-2) / var(--tw-text-opacity));
          }
          .bg-green-100,
          .bg-green-200 {
            background-color: rgb(var(--color-green-3));
          }
          .text-green-700 {
            color: $white !important;
          }
          .border-red-600 {
            background-color: rgb(var(--color-red-3));
            color: #fff;
            border-color: #ff00005c;
          }
          .border-green-600 {
            background-color: rgb(var(--color-green-3));
            color: #fff;
            border-color: rgb(var(--color-green-3));
          }
          border-color: var(--color-border-1);

          /* For Sorter*/
          &.ant-table-column-sort {
            background-color: var(--color-component-gray-1);
          }
          &.ant-table-cell-row-hover {
            background-color: var(--color-component-gray-1);
          }
        }

        .ant-table-row-selected {
          .ant-table-cell {
            background-color: var(--color-component-gray-2);
          }
        }
        .ant-table-placeholder {
          background-color: transparent;
          .ant-table-cell {
            border-bottom: 0px !important;
          }
          .ant-empty-description {
            color: $white;
          }
          &:hover {
            .ant-table-cell {
              background-color: transparent !important;
            }
          }
        }
      }
      .ant-table-cell-scrollbar {
        box-shadow: none;
      }
      // }
    }

    .ant-table-expanded-row .ant-table-cell {
      background-color: var(--color-component-gray-1) !important;
    }
    .ant-table-row-expand-icon {
      border-color: var(--color-component-gray-2) !important;
      background-color: var(--color-component-gray-1) !important;
      color: $white !important;
      // &:hover {
      // }
    }
    .ant-spin {
      background-color: rgb(var(--color-base-3));
      backdrop-filter: blur(4px);
    }

    .ant-pagination {
      // margin-right: 16px !important;
      .ant-pagination-item {
        border-color: var(--color-component-gray-1) !important;
        background-color: var(--color-component-gray-1) !important;
        color: $white !important;
        &:hover {
          background-color: #2e2e2e !important;
        }
        &.ant-pagination-item-active {
          border-color: rgb(var(--color-base-3)) !important;
          background-color: rgb(var(--color-gray-300)) !important;
          color: $white !important;
        }
      }
      .ant-pagination-item-link,
      .ant-pagination-item-ellipsis {
        color: $white !important;
        // .anticon {
        //   color: $white !important;
        // }
      }
      .ant-pagination-next,
      .ant-pagination-prev {
        border: 1px solid var(--color-component-gray-2) !important;
        background-color: var(--color-component-gray-1) !important;
        color: $white !important;
        &:hover {
          background-color: #2e2e2e !important;
        }
        .anticon {
          color: $white !important;
        }
        &.ant-pagination-disabled {
          opacity: 0.5;
        }
      }
    }
    .ant-pagination-options {
      .ant-select {
        .ant-select-arrow {
          color: $white !important;
        }
        .ant-select-selector {
          border: 1px solid rgb(var(--color-gray-100)) !important;
          box-shadow: --color-shadow-soft;
          background-color: rgb(var(--color-gray-100)) !important;
          color: $white !important;
          &::after {
            color: $white !important;
          }
          .ant-select-selection-item {
            color: $white !important;
          }
        }
        .ant-select-dropdown {
          background-color: rgb(var(--color-gray-100)) !important;
          .ant-select-item {
            color: $white !important;
            &.ant-select-item-option-selected {
              background-color: rgb(var(--color-gray-200)) !important;
            }
          }
        }
      }
    }
  }

  .drawer-root-className {
    .ant-drawer-content {
      background-color: rgb(var(--color-base-1));
      .ant-drawer-header {
        border-color: var(--color-border-2) !important;
      }
      .ant-drawer-footer {
        border-color: var(--color-border-2) !important;
      }
      .text-neutral-900,
      .text-gray-700 {
        color: $white;
      }
    }
  }
  /*===============================     Dark Dropdown Opened   ===========================================*/
  .dropdownOverlay {
    // .ant-dropdown-menu-item-active {
    //   background-color: #eaf4fb !important;
    // }

    // .ant-dropdown-menu-item-active {
    //   &:hover {
    //     background-color: #e9f5ff !important;
    //   }
    // }
    &.dowpdownDark {
      border: 1px solid var(--color-border-2) !important;
      border-radius: 10px !important;
      .ant-dropdown-menu {
        background-color: rgb(var(--color-base-1));
        box-shadow: var(--color-shadow-soft) !important;
      }
      .ant-dropdown-menu-item-selected {
        background-color: rgb(var(--color-base-2)) !important;
      }
      .ant-dropdown-menu-item-active:hover {
        background-color: rgb(var(--color-base-2)) !important;
      }
    }
  }
  .dowpdownDark {
    .text-neutral-900 {
      color: $white;
    }
    .text-sky-700 {
      color: $white !important;
      font-weight: 500 !important;
    }
  }
  .dropdownOverlay {
    &.dowpdownDark {
      .ant-dropdown-menu {
        .ant-dropdown-menu-item-selected {
          background-color: rgb(var(--color-base-2)) !important;
          .text-neutral-900 {
            color: $white;
          }
          .text-sky-700 {
            color: $white !important;
            font-weight: 500 !important;
          }
        }
        .ant-dropdown-menu-title-content {
          color: $white !important;
        }
        .ant-dropdown-menu-item-disabled {
          .ant-dropdown-menu-title-content {
            color: var(--color-component-gray-4) !important;
          }
          &:hover {
            background-color: rgb(var(--color-base-2)) !important;
          }
        }
      }
    }
  }
  .dropdownOverlay {
    &.title-filter-dropdown {
      .ant-dropdown-menu-item {
        &.ant-dropdown-menu-item-selected {
          background-color: rgb(var(--color-gray-300)) !important;
          .fontWeight {
            font-weight: 500;
          }
          .text-color-secondary {
            color: #cccccc;
          }
          .badgeColor {
            background-color: #182331;
            color: $white !important;
            font-weight: 500;
          }
        }
      }
    }
  }
  /*===============================     Dark Dropdown closed   ===========================================*/

  /*===============================     Dark Antd Date Picker     ===========================================*/
  .dark-ant-picker {
    &.ant-picker {
      .ant-picker-input {
        color: $white !important;
        input {
          color: $white !important;

          &::placeholder {
            color: #818181 !important;
          }

          &:disabled {
            color: #cccccc !important;
            &::placeholder {
              color: $white !important;
            }
          }
        }
        .ant-picker-suffix {
          color: $white !important;
          .anticon {
            color: $white !important;
          }
        }
      }
      &.ant-picker-disabled {
        background-color: rgb(68 64 60) !important;
      }

      &.ant-picker-status-error {
        background-color: rgb(38 38 38) !important;
      }
    }
  }
  .dark-ant-picker-dropdown {
    .ant-picker-panel-container {
      background-color: rgb(23 23 23) !important;
      .ant-picker-header {
        color: #ffffffd3 !important;
        button {
          color: #ffffff79;
          &:hover {
            color: $white;
          }
        }
        .ant-picker-header-view {
          button {
            color: #ffffffd3 !important;
            &:hover {
              color: $primary_color !important;
            }
          }
        }
      }
      .ant-picker-content {
        thead {
          tr {
            th {
              color: #ffffffd3 !important;
            }
          }
        }
        .ant-picker-cell {
          color: #ffffff79 !important;
          &:hover {
            .ant-picker-cell-inner {
              background-color: $darkGray !important;
            }
          }
          &.ant-picker-cell-in-view {
            color: $white !important;
          }
          &.ant-picker-cell-today {
            .ant-picker-cell-inner {
              // border-color: $primary_color !important;

              &::before {
                border-color: $primary_color !important;
              }
            }
          }
          &.ant-picker-cell-selected {
            .ant-picker-cell-inner {
              background-color: $primary_color !important;
            }
          }
          &.ant-picker-cell-end {
          }
        }

        // ==============

        .ant-picker-time-panel-column {
          scrollbar-color: rgba(53, 53, 53, 0.747) transparent;
          .ant-picker-time-panel-cell {
            .ant-picker-time-panel-cell-inner {
              color: $white !important;
            }

            &:hover {
              .ant-picker-time-panel-cell-inner {
                background-color: $darkGray !important;
              }
            }

            &.ant-picker-time-panel-cell-selected {
              .ant-picker-time-panel-cell-inner {
                background-color: $primary_color !important;
              }
            }
          }
        }
      }

      // ====================

      .ant-picker-footer {
        .ant-picker-ranges {
          .ant-picker-now-btn {
            color: $white !important;
            &:hover {
              color: rgb(var(--color-blue-2) / 20%) !important;
            }
          }
          .ant-picker-ok {
            .ant-btn {
              background-color: $primary_color !important;

              &:hover {
                background-color: rgb(117, 38, 214) !important;
              }

              &:disabled {
                color: $white !important;
                border-color: $primary_color !important ;
                opacity: 0.5 !important;
              }
            }
          }
        }
      }
    }
  }
  .dark-ant-picker {
    &.ant-picker {
      .ant-picker-range-separator,
      .ant-picker-clear {
        .anticon {
          color: $white !important;
        }
      }
      .ant-picker-input {
        color: $white !important;

        input {
          color: $white !important;

          &::placeholder {
            color: #818181 !important;
          }

          &:disabled {
            color: #cccccc !important;

            &::placeholder {
              color: $white !important;
            }
          }
        }

        .ant-picker-suffix {
          color: $white !important;

          .anticon {
            color: $white !important;
          }
        }
      }

      &.ant-picker-disabled {
        background-color: rgb(68 64 60) !important;
      }

      &.ant-picker-status-error {
        background-color: rgb(38 38 38) !important;
      }
    }
    .ant-picker-clear {
      background-color: transparent !important;
    }
    .ant-picker-suffix {
      .anticon {
        &.anticon-calendar {
          color: $white !important;
        }
      }
    }
  }
  .dark-ant-picker-dropdown {
    .ant-picker-panel-container {
      background-color: rgb(var(--color-base-2)) !important;

      .ant-picker-header {
        color: #ffffffd3 !important;

        button {
          color: #ffffff79;

          &:hover {
            color: $white;
          }
        }

        .ant-picker-header-view {
          button {
            color: #ffffffd3 !important;

            &:hover {
              color: $primary_color !important;
            }
          }
        }
      }

      .ant-picker-content {
        thead {
          tr {
            th {
              color: #ffffffd3 !important;
            }
          }
        }

        .ant-picker-cell {
          color: #ffffff79 !important;

          // .ant-picker-cell-in-view {
          //   &:hover {
          //     .ant-picker-cell-inner {
          //       background-color: $darkGray !important;
          //     }
          //   }
          // }
          &.ant-picker-cell-in-view {
            color: $white !important;
            &.ant-picker-cell-in-range,
            &.ant-picker-cell-selected,
            &.ant-picker-cell-range-end {
              &::before {
                background-color: rgb(var(--primary-1) / 0.3);
              }
            }
            &:hover {
              .ant-picker-cell-inner {
                background-color: rgb(var(--primary-1)) !important;
                &::after {
                  background-color: rgb(var(--primary-1) / 0.3);
                }
              }
            }
          }
          &.ant-picker-cell-range-end {
            .ant-picker-cell-inner {
              background-color: $primary_color !important;
            }
          }

          &.ant-picker-cell-range-start {
            &::before {
              background-color: rgb(var(--primary-1) / 0.3) !important;
            }
          }
          &.ant-picker-cell-today {
            .ant-picker-cell-inner {
              // border-color: $primary_color !important;

              &::before {
                border-color: $primary_color !important;
              }
            }
          }

          &.ant-picker-cell-selected {
            .ant-picker-cell-inner {
              background-color: $primary_color !important;
            }
          }

          &.ant-picker-cell-end {
          }
        }

        // ==============

        .ant-picker-time-panel-column {
          scrollbar-color: rgba(53, 53, 53, 0.747) transparent;

          .ant-picker-time-panel-cell {
            .ant-picker-time-panel-cell-inner {
              color: $white !important;
            }

            &:hover {
              .ant-picker-time-panel-cell-inner {
                background-color: $darkGray !important;
              }
            }

            &.ant-picker-time-panel-cell-selected {
              .ant-picker-time-panel-cell-inner {
                background-color: $primary_color !important;
              }
            }
          }
        }
      }

      // ====================

      .ant-picker-footer {
        .ant-picker-ranges {
          .ant-picker-now-btn {
            color: $white !important;

            &:hover {
              color: rgb(var(--color-blue-2) / 20%) !important;
            }
          }

          .ant-picker-ok {
            .ant-btn {
              background-color: $primary_color !important;

              &:hover {
                background-color: rgb(117, 38, 214) !important;
              }

              &:disabled {
                color: $white !important;
                border-color: $primary_color !important;
                opacity: 0.5 !important;
              }
            }
          }
        }
      }
    }
  }
  /*===============================     Dark Antd Date Picker End    ===========================================*/

  .MuiSelect-select,
  .MuiSvgIcon-root {
    color: $white !important;
  }
  .modal-footer,
  .modal-header {
    border-color: var(--color-border-2) !important;
  }
  .ant-form-item-label {
    label {
      color: rgb(var(--color-text-primary)) !important;
    }
  }
  .custm_modal {
    .ant-modal-content {
      color: rgb(var(--color-text-primary));
      background-color: rgb(var(--color-base-1)) !important;
    }
  }
  .ant-input-status-error {
    background-color: rgb(var(--color-gray-200)) !important;
  }

  /*======================= Ant Popover =================================*/
  .ant-popover {
    .ant-popover-inner {
      background-color: rgb(var(--color-base-4)) !important;
      border: 1px solid var(--color-border-2) !important;
      .ant-color-picker-input-container {
        .ant-select-selector {
          padding-left: 4px !important;
        }
        .ant-select-arrow {
          color: $white !important;
        }
      }
      .ant-color-picker-input {
        .ant-input-affix-wrapper {
          border-color: var(--color-component-gray-1) !important;
          background-color: rgb(var(--color-base-2)) !important;
          color: $white !important;
          .ant-input-prefix {
            color: $white !important;
          }
          .ant-input {
            background-color: rgb(var(--color-base-2)) !important;
            color: $white !important;
          }
        }
      }

      .ant-collapse-header {
        .ant-collapse-expand-icon {
          color: $white !important;
        }
        .ant-collapse-header-text {
          .ant-color-picker-presets-label {
            color: $white !important;
          }
        }
      }
    }
  }

  /*====================== Ant Select ======================*/
  .custm-select,
  .ant-select {
    .ant-select-selector {
      // border: 1px solid var(--color-component-gray-2) !important;
      border: 1px solid rgb(var(--color-border-2)) !important;
      // background-color: var(--color-component-gray-1) !important;

      background-color: rgb(var(--color-gray-100)) !important;
      &:hover,
      &.ant-select-open {
        box-shadow: var(--color-shadow-soft) !important;
      }
      .ant-select-selection-item {
        color: $white;
      }
      .ant-select-selection-search-input {
        color: $white;
      }
    }
    &.ant-select-open {
      .ant-select-selector {
        background-color: rgb(var(--color-gray-200)) !important;
      }
    }
    &.ant-select-multiple {
      .ant-select-selector {
        .ant-select-selection-item {
          // background-color: var(--color-component-gray-2) !important;
          background-color: rgb(var(--color-gray-200)) !important;
          .anticon {
            color: $white !important;
          }
        }
      }
    }
    .ant-select-selection-search-input {
      color: $white !important;
    }
  }
  .ant-select-dropdown {
    background-color: rgb(var(--color-base-1)) !important;
    backdrop-filter: blur(20px);
    .ant-select-item {
      color: $white !important;
      &.ant-select-item-option-selected {
        background-color: var(--color-component-gray-2) !important;
      }
      &.ant-select-item-option-active {
        background-color: var(--color-component-gray-3) !important;
      }
    }
  }
  /*====================== Ant Select ======================*/

  .ant-radio-wrapper {
    .ant-radio {
      .ant-radio-inner {
        background-color: var(--color-component-gray-3) !important;
        border-color: var(--color-component-gray-3) !important;
      }
      &.ant-radio-checked {
        background-color: rgb(var(--primary-1)) !important;
        border-color: rgb(var(--primary-1)) !important;
      }
    }
  }

  .switch {
    background-color: rgb(var(--color-gray-100));
  }
  /* Dark theme end*/
  .primary_skeleton {
    .ant-skeleton-active .ant-skeleton-paragraph > li,
    .ant-skeleton-title,
    .ant-skeleton-image,
    .ant-skeleton-input,
    .ant-skeleton.ant-skeleton-active .ant-skeleton-input,
    .ant-skeleton-paragraph > li {
      // background: linear-gradient(
      //   90deg,
      //   rgb(48 48 48 / 6%) 25%,
      //   rgb(82 82 82 / 15%) 37%,
      //   rgba(78, 78, 78, 0.06) 63%
      // );
      background: linear-gradient(
        90deg,
        rgba(71, 71, 71, 0.13) 25%,
        rgb(52 52 52 / 15%) 37%,
        rgba(56, 56, 56, 0.08) 63%
      );
      animation: mymove 1.4s ease infinite !important;
      background-size: 400% 100% !important;
    }
  }

  .ant-upload-drag {
    border-color: var(--color-border-2);
    &:hover {
      border-color: rgb(var(--primary-2));
    }
  }
}
.ant-form-item-label {
  padding-bottom: 2px !important;
}
// Light theme

@keyframes mymove {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.light {
  .ant-dropdown {
    &.dropdownOverlay {
      &.title-filter-dropdown {
        .ant-dropdown-menu-item {
          &.ant-dropdown-menu-item-selected {
            background-color: var(--color-component-gray-1) !important;
            .fontWeight {
              font-weight: 600 !important;
            }
            .textColor {
              color: rgb(var(--primary-1)) !important;
            }
            .badgeColor {
              background-color: rgb(var(--primary-1));
              color: $white !important;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .ant-slider {
    .ant-slider-rail {
      background-color: rgb(96 96 96 / 48%);
    }
    .ant-slider-track {
      background-color: #0074b8;
    }
    .ant-slider-handle {
      &::after {
        background-color: #0074b8;
        box-shadow: 0 0 0 2px #119cec;
      }
    }
  }
  .ant-input-number-disabled {
    &.ant-input-number-outlined {
      border-color: transparent !important;
    }
  }
  .ant-input-affix-wrapper {
    &.ant-input-affix-wrapper-focused {
      border: 1px solid rgb(var(--primary-1)) !important;
      outline: rgb(var(--primary-1) / 50%) !important;
    }
  }
  .ant-select {
    .ant-select-selector {
      border-color: var(--color-border-1);
    }
  }
}

// login page

.auth-wrapper {
  &::before {
    width: 238px;
    height: 233px;
    content: ' ';
    position: absolute;
    top: -55px;
    left: -40px;
    background-image: url("data:image/svg+xml,%3Csvg width='239' height='234' viewBox='0 0 239 234' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='88.5605' y='0.700195' width='149' height='149' rx='19.5' stroke='%237367F0' stroke-opacity='0.16'/%3E%3Crect x='0.621094' y='33.761' width='200' height='200' rx='10' fill='%237367F0' fill-opacity='0.08'/%3E%3C/svg%3E%0A");
  }
  &::after {
    width: 180px;
    height: 180px;
    content: ' ';
    position: absolute;
    z-index: -1;
    bottom: -30px;
    right: -56px;
    background-image: url("data:image/svg+xml,%3Csvg width='181' height='181' viewBox='0 0 181 181' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1.30469' y='1.44312' width='178' height='178' rx='19' stroke='%237367F0' stroke-opacity='0.16' stroke-width='2' stroke-dasharray='8 8'/%3E%3Crect x='22.8047' y='22.9431' width='135' height='135' rx='10' fill='%237367F0' fill-opacity='0.08'/%3E%3C/svg%3E");
  }
}
