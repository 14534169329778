body {
  padding: 0;
  margin: 0;
  font-family: $inter;
  overflow-x: hidden;
  background-color: $gray;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
}

p,
* {
  padding: 0;
  margin: 0;
}

.ant-form-item-explain-error {
  color: red;
  font-size: 12px;
  // margin-top: 10px;
}

.backgroundWhite {
  background-color: $white;
}

.padding {
  padding: 2rem;
}

.rightContent,
.leftContent {
  background-color: $white;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 500;
}

.paddingLeft {
  padding-left: 2rem;
}

.paddingRight {
  padding-right: 2rem;
}

.headingSection {
  .leftArrow {
  }

  .heading {
  }

  .subHead {
  }
}

.stratery_label {
  cursor: pointer;
}

.stratery_input:checked + .stratery_label {
  background-color: $secondary_purple;
}

.predefinedLabel {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $secondary_color;
  position: relative;
  cursor: pointer;
  float: right;
}

.predefinedInput:checked + .predefinedLabel {
  background-color: $primary_color;
  .checkIcon {
    display: block !important;
    width: 16px;
    height: 16px;
  }
}

.instruction {
  .flex {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;

    .fixedWidth {
      width: 350px;
      display: flex;
      flex-direction: column;

      .instructionS {
        background-color: $white;
        border-radius: 10px;
        height: 100vh;
        width: 300px;
        position: fixed;

        .djjfhed {
          overflow: auto;
          max-height: calc(100vh - 200px);

          .accordion-item {
            border: none;

            .accordion-header {
              button {
                border: none;
                background-color: transparent;
                color: $black;

                &:focus {
                  outline: none;
                  box-shadow: none;
                }
              }
            }

            .accordion-body {
              padding: 0 15px;
            }
          }
        }
      }
    }
  }

  .mleSje {
    margin-left: 300px;
    padding: 30px;
    max-height: calc(100vh - 200px);
    overflow: auto;
    ul {
      margin-top: 30px;
      li {
        margin-bottom: 10px;
        p {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}

.nav-link {
  color: #000;
  margin: 5px 0;
}

.nav-link.active {
  background: $secondary_purple !important;
  color: #000 !important;
  font-weight: 400 !important;
}

.informationBelow {
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    list-style-type: none;
    border: 1px solid #adadad;
    border-radius: 10px;
    li {
      flex: 1 0;
      text-align: center;
      padding: 15px 10px;
      border-right: 1px solid #adadad;
      font-size: 14px;
      strong {
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}
.ant-tabs {
  font-family: 'Inter', sans-serif !important;
  .ant-tabs-tab {
    font-family: 'Inter', sans-serif !important;
    font-weight: 500;
    padding: 15px 13px !important;
    margin: 0 0 0 0px !important;
  }
}
.dark {
  .ant-tabs {
    .ant-tabs-tab {
      color: rgb(var(--color-text-primary));
      font-weight: 500;
    }
    .ant-tabs-nav {
      &::before {
        border-bottom-color: var(--color-border-2);
      }
    }
  }
}
