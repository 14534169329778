* {
    margin: 0;
    padding: 0;
    box-sizing: 0;
}

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
    font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
        Roboto, 'Helvetica Neue', Arial, sans-serif;
    /* font-family: roboto, helvetica, sans-serif; */
}

a {
    text-decoration: none !important;
}
/* code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
} */

.login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
}
.login-form {
    border-radius: 5px;
    max-width: 400px;
    width: 100%;
    padding: 30px;
    background: transparent;
}

.form-button {
    padding: 8px 0;
    border: 1px transparent;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
}
.heading-update {
    margin-left: 12px;
    font-size: 24px;
    color: #fff;
}
.table-bg {
    background: white;
}

.users-table p {
    margin-bottom: 0;
}

.text-muted {
    color: #8898aa !important;
}
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}
.btn-circle {
    border-radius: 50px;
}
.user-table tbody tr .category-select {
    max-width: 150px;
    border-radius: 20px;
}
