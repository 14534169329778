$primary__blue__bg: #4074f9;
$blue__off__bg: #eff0ff;
$primary__green: #17c653;
$primary__red: #f8285a;
$primary__mute: #99a1b7;
$primary__white: #fff;
$primary__offblue: #e9f3ff;
$primary__blue: #1b84ff;
$primary__bg__ofgreen: #e7fffb;
$primary__bg__ofred: #fff0f3;
$primary__bg__dark: #fff;

// Dark Theme Colors

.dark-theme {
  $primary__blue__bg: #4074f9;
  $blue__off__bg: #eff0ff;
  $primary__green: #17c653;
  $primary__red: #f8285a;
  $primary__mute: #99a1b7;
  $primary__white: #fff;
  $primary__offblue: #e9f3ff;
  $primary__blue: #1b84ff;
  $primary__bg__ofgreen: #192a27 !important;
  $primary__bg__ofred: #fff0f3;
  $primary__bg__dark: #15171c;

  background-color: #0f1014 !important;

  .bg__white {
    background-color: #15171c;
    border: solid 1px #1e2027;
  }

  .btn__back {
    background-color: #172331;
    color: $primary__blue !important;
    border: solid 1px $primary__blue;
  }
  .strategy__sml__details {
    ul {
      li {
        border: solid 1px #333;
        p {
          color: #fff;
        }
      }
    }
  }
  .strategy__sml__btns {
    li {
      a {
        background-color: #172331;
      }
    }
  }
  .card-body {
    background-color: #15171c;
  }

  .card {
    .crd__row {
      background-color: #15171c;
      color: #99a1b7;
    }
  }
  .tbl__body__inner {
    background-color: #15171c;
    color: #99a1b7;
    .coll {
      border-color: #333;
    }
  }
  .accordion-header {
    .accordion-button {
      &::after {
        background-image: none !important;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #ffffff transparent transparent transparent;
        transform: rotate(0deg);
        // border:solid 1px #1b84ff;
      }
    }
  }

  .instruction {
    .instructionS {
      background-color: #15171c !important;
      border: solid 1px #1e2027;
    }

    .mleSje {
      background-color: #fff;
      margin-top: 20px;
      max-height: calc(100vh - 20px);
    }
  }

  #v-pills-tab {
    .nav-link {
      span {
        color: #99a1b7;
      }

      label {
        background-color: #172331;
        border: dashed 1px $primary__blue;
        color: $primary__blue;
      }
    }
  }

  .ant-switch[aria-checked='false'] {
    background-color: #333;
  }

  .trd__data__head {
    .coll {
      &::after {
        background-color: #333;
      }
    }
  }

  .badge.bg-success {
    background-color: #192a27 !important;
  }

  .brockerCard__body {
    .brocker__img {
      background-color: #1f2127;
    }
  }

  .brockerCard__body {
    .brocker__info {
      border-top: solid 1px #1e2027;
    }
  }

  .badge.bg-danger {
    background-color: #231a1c !important;
  }

  .view__button {
    background-color: #172331;
  }

  .BrockerTable__container {
    background-color: #15171c;
    padding: 0px;
    border-radius: 0px;
  }

  .brockerCard__body {
    background-color: #15171c;
    border: solid 1px #1e2027;
  }

  .brockerCard__body {
    .brocker__info {
      .brocker__name {
        color: #fff;
      }
    }
  }

  .primary__bg__ofgreen {
    background-color: #192a27;
  }

  .btn-action {
    border: solid 1px #32363f;
  }

  .user__info__box {
    border: solid 1px #1e2027;
    background-color: #15171c;
  }

  .btn__print.deactive {
    background-color: #172331;
    color: #1b84ff;
    border: solid 1px #1b84ff;
  }

  .btn__delete.deactive {
    background-color: #172331;
    color: #1b84ff;
    border: solid 1px #1b84ff;
  }

  .dash_separate {
    border-bottom: dashed 1px #3f424e;
  }

  .grd__info {
    h4 {
      color: #fff;
    }
  }

  .small__container {
    .nav.nav-tabs {
      border: solid 1px #1e2027;
      background-color: #15171c;
    }
  }

  .User__Details__Card {
    .Details__Card__head {
      border-bottom: solid 1px #1e2027;
      background-color: #15171c;
    }
  }

  .User__Details__Card {
    border: solid 1px #1e2027;
  }

  .separate__between {
    border-bottom: solid 1px #1e2027;
  }

  .User__Details__Card {
    .Details__Card__Body {
      // span {
      //   color: #fff;
      // }
    }
  }

  .btn_default {
    background-color: #172331;
    color: $primary__blue;
  }

  .brocker__name {
    span {
      color: $primary__mute;
    }
  }

  .ant-table-tbody {
    tr.ant-table-placeholder {
      &:hover {
        td {
          background: #1d2026;
        }
      }
    }
  }

  .small__container {
    .nav.nav-tabs {
      .nav-link.active {
        color: #fff !important;
      }
    }
  }

  .setting_grd {
    p {
      color: #fff;
    }
  }

  .ant-picker-dropdown.cust_popup {
    .ant-picker-panel-container {
      background-color: #25282f;
      border: solid 1px #2f323b;
    }

    .ant-picker-header {
      border-bottom: solid 1px #292c34;
    }

    .ant-picker-content {
      th {
        color: #fff;
      }
    }

    .ant-picker-header {
      color: #fff;

      button {
        color: #fff !important;
      }
    }

    .ant-picker-cell-in-view {
      color: #99a1b7;
    }

    .ant-picker-panel {
      border: solid 1px #1e2027;
    }

    .ant-picker-cell-disabled {
      color: #3d475c;
    }
  }

  .react-calendar-heatmap {
    .color-empty {
      fill: #23242b;
    }
  }

  .primary__bg__ofred {
    background-color: #231a1c;
  }

  .top__bar {
    background-color: #0d0e12;
  }

  .primary__bg__dark {
    background-color: $primary__bg__dark;
  }

  .btn__collapse {
    box-shadow: -5px 0px 10px 0px #333 !important;
  }

  .heatmap__bg {
    background-color: transparent;

    h6.align-items-center {
      color: #777;
    }
  }

  .heat__map__bg {
    border: solid 1px #1e2027;
  }

  .react-calendar-heatmap text {
    font-size: 7px;
  }

  .ant-pagination-item-active {
    background-color: $primary__mute;
  }

  .ant-pagination-item {
    background-color: #15171c;
    border: solid 1px #222;
    color: #99a1b7;
  }

  .ant-pagination-item-link {
    background-color: #15171c;
    border: solid 1px #222;
    color: #99a1b7;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #15171c;
    border: solid 1px #222;
    color: #99a1b7;
  }

  .ant-picker {
    background-color: #172331 !important;
    color: #fff !important;
    border-color: $primary__blue;
  }
  // .ant-input {
  //   background-color: #172331 !important;
  //   color: #fff !important;
  //   border-color: $primary__blue;
  // }

  .ant-picker-input {
    input {
      color: #fff;
    }
  }

  .ant-picker-input {
    input::placeholder {
      color: $primary__mute !important;
    }
  }

  .cstm__dt__pick {
    .ant-picker-suffix,
    .ant-picker-clear {
      background-color: #172331;
      color: #1b84ff;
    }
  }

  .ant-checkbox-inner {
    background-color: #182432;
    border: solid 1px #747b94;
    border-width: 0px;
  }
  .ant-checkbox {
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #1890ff;
        border-color: #1890ff;
      }
    }
  }
  .btn__collapse {
    box-shadow: 5px 0px 10px 0px #222;
  }

  .sidebar {
    box-shadow: none;
    border-right: solid 1px #222;
  }

  .off__blue {
    background-color: #15171c;
    border: solid 1px #1e2027;
  }

  .portfolio__list__card {
    background-color: transparent;
  }

  .sep__line {
    background-color: #15171c;
    border: solid 1px #1e2027;
  }

  .strtgy__card {
    background-color: #15171c;
    border: solid 1px #1e2027;
  }

  .strtgy__table__box {
    background-color: #15171c;
    border: solid 1px #1e2027;
  }

  .tbl_data .card-header {
    background-color: #15171c;
    border: solid 1px #1e2027;
  }

  .close__order__data {
    background-color: #15171c;
    border: solid 1px #1e2027;
  }

  .bg__icon {
    background-color: #24262c;
  }

  .ant-table-thead > tr > th {
    background-color: #15171c;
    // color: #99a1b7;
    color: #99a1b7;
    border-bottom: solid 1px var(--color-border-2);

    &::before {
      background-color: #333 !important;
    }
  }

  .ant-table-tbody > tr > td {
    background-color: #15171c;
  }

  .ant-table-tbody > tr > td {
    border-bottom: solid 1px #333;
  }

  .ant-table-row:hover > td {
    background-color: #1d2026 !important;
  }

  .strtgy__ttl,
  .pnl__ttl {
    color: #fff;
  }

  .portfolio__list__card {
    div {
      h3 {
        color: #fff;
      }
    }
  }

  .strtgy__table__box {
    ul {
      li.active {
        color: #fff;
      }
    }
  }

  .strgy_ttl_box {
    background-color: #172331;

    &:hover {
      background-color: $primary__blue;
      color: #fff;
    }
  }

  .strtgy__thumb {
    background-color: #172331;
  }

  .strtgy__card__details {
    h3 {
      color: #fff;
    }
  }

  .strtgy__card__sub__details {
    border-top: dashed 1px #363843;
  }

  .vrtl__trd__badge {
    background-color: #31271a;
  }

  .lv__trd__badge {
    background-color: #192a27;
  }

  .badge__green {
    background-color: #1b2719;
  }

  .sidebar__bottom__area {
    li {
      span.divider {
        border: dashed 1px #222;
      }
    }
  }

  .btn__collapse {
    background-color: #15171c;
    border: solid 1px #555;
    color: #fff;
  }

  .sidebar__bottom__area {
    li {
      button {
        svg {
          fill: #484e62;
        }

        &:hover {
          svg {
            fill: $primary__blue;
          }
        }
      }

      a {
        svg {
          fill: #484e62;
        }

        &:hover {
          svg {
            fill: $primary__blue;
          }
        }
      }
    }
  }

  .trd__data__head {
    color: #99a1b7;
    border-bottom: solid 1px var(--color-border-2);
  }

  .close__order__data {
    svg {
      stroke: $primary__mute;
    }

    span {
      color: $primary__mute;
    }

    .ant-empty-img-simple-path {
      fill: $primary__mute;
    }

    .ant-empty-img-simple-ellipse {
      fill: #fff;
    }
  }

  .ant-empty-img-simple-path {
    fill: $primary__mute;
  }

  .ant-empty-img-simple-g {
    stroke: $primary__mute;
  }

  .ant-empty-img-simple-ellipse {
    fill: #ddd;
  }

  .ant-empty-description {
    color: $primary__mute;
  }
}

.cstm_brk {
  display: flex;
  flex-direction: column;
}

.instruction {
  .instructionS {
    background-color: transparent !important;
    border: solid 1px #f1f1f4;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  }
}

.BrockerTable__container {
  background-color: #15171c;
  padding: 0px;
  border-radius: 0px;
}

.primary__bg__dark {
  background-color: $primary__bg__dark;
}

.heat__map__bg {
  border: solid 1px #efefef;
  padding: 10px;
  border-radius: 10px;
}

.sidebar {
  height: 100vh;
  top: 0;
  box-shadow: 0px 2px 10px 2px #ddd;
  padding: 7px;
  background-color: #0d0e12;
  z-index: 99;
}

.left__bar {
  margin-left: 100px;
  margin-right: 35px;
  transition: all 0.3s;
  margin-top: 70px;
}

.top__bar {
  margin-right: 0px;
  background-color: #fff;
  border-radius: 0;
  padding: 10px;
  margin-bottom: 15px;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  position: fixed;
  top: 0;
  z-index: 9;
  width: 100%;
}

.sidebar__bottom__area {
  li {
    span.divider {
      border: dashed 1px #efefef;
      height: 15px;
      width: 1px;
      display: flex;
      margin-right: 5px;
      margin-left: 5px;
    }

    a {
      // font-size: 17px;

      &:hover {
        background-color: transparent;

        svg {
          fill: $primary__blue;
        }
      }

      svg {
        fill: #c6c9d3;
      }
    }

    button {
      background-color: transparent;
      font-size: 17px;

      svg {
        fill: #c6c9d3;
      }

      &:hover {
        background-color: transparent !important;
        // color: #1b84ff !important;

        svg {
          fill: #1b84ff;
        }
      }
    }
  }

  li.active {
    button {
      svg {
        fill: #1b84ff;
      }
    }
  }
}

.sidebar__area > a > img {
  padding: 10px;
}

.strategy_canvas {
  .offcanvas-header {
    border-bottom: solid 1px #ddd;
  }
}

.sidebar__area {
  ul {
    li {
      list-style: none;
      display: flex;
      margin-top: 2px;
      margin-bottom: 2px;
      width: 100%;

      a.active {
        background-color: #1e2029;
        color: #fff !important;
      }

      a {
        padding: 10px 12px;
        border-radius: 8px;
        color: #999 !important;
        display: flex;
        gap: 10px;
        font-size: 16px;
        flex-shrink: 0;
        align-items: center;
        height: 40px;

        &:hover {
          background-color: #1e2029;
          color: #fff !important;
        }
      }

      button {
        padding: 10px 15px;
        border-radius: 4px;
        color: $primary__blue__bg !important;

        &:hover {
          background-color: $primary__blue__bg !important;
          color: #fff !important;
        }
      }
    }
  }
}

.sidebar__bottom__area {
  bottom: 30px;
  left: 1px;
}

.btn_swipe_exit {
  .button {
    background-color: #fff;
  }

  .layer {
    border-radius: 30px;
  }
}

.tbl__hd {
  background-color: $blue__off__bg;
  padding: 10px;
  margin-top: 2px;
}

.off__blue {
  background-color: #fff;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border: solid 1px #f1f1f4;
}

.cstm__accordion {
  margin-bottom: 200px;

  .accordion-button {
    padding: 10px;
    border-radius: 0px !important;
    border: none;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .accordion-body {
    padding: 0px;
  }
}

.fnt-sml {
  font-size: 14px !important;
}

.tbl_data {
  .card-header {
    background-color: $primary__white;
    border: none;
    padding: 0px 0px;
    border-radius: 0px;
    font-weight: 500;
  }

  .card {
    border-radius: 0px;
    border: none;
    font-size: 14px;
  }
}

.card-body {
  .crd__row {
    &:nth-last-child(1) {
      border: none !important;
    }
  }
}

.crd__row {
  border-bottom: solid 1px #ddd !important;
  padding: 10px 0px;
}

.badge__green {
  background-color: #def4da;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  color: #43a233;
}

.badge__blue {
  background-color: $primary__blue__bg;
  border-radius: 18px;
  padding: 5px 15px;
  font-size: 12px;
  color: #fff;
}

.pnl__ttl {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 2px;
}

.pnl__details {
  color: $primary__red !important;
}

.dwn__arrow {
  svg {
    color: $primary__red;
  }
}

.primary__text__mute {
  color: $primary__mute;
}

.portfolio__list__card {
  background-color: $primary__white;
  gap: 10px;

  div {
    h3 {
      font-size: 16px;
      margin: 0;
    }

    span {
      font-size: 14px;
      color: $primary__mute;
    }
  }
}

.heatmap__bg {
  padding: 5px 0px;
}

.react-calendar-heatmap text {
  font-size: 6px;
  color: #99a1b7;
}

.cstm__mob__scroll {
  display: flex;
  overflow-x: auto;
  flex-wrap: initial;
  padding-bottom: 0px;
  padding-left: 0;
  padding-right: 25px;
}

.primary__red {
  color: $primary__red;
}

.primary__green {
  color: $primary__green;
}

.sep__line {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  padding: 8px;
  border: solid 1px #f1f1f4;
  flex-basis: 22%;
  align-items: start;

  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   right: 20px;
  //   border-right: solid 1px #ddd;
  //   height: 70px;
  //   width: 2px;
  // }
}

.sep__line__last {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.strtgy__card {
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  width: 25%;
  flex: 0 0 auto;
  margin-left: 10px !important;
  border: solid 1px #f1f1f4;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  // overflow: hidden;

  &:hover {
    .strtgy__card__more {
      transform: translateX(0px);
      transition: all 0.3s;
    }
  }
}

.strtgy__thumb {
  // background-color: #4074f9;
  background-color: $primary__offblue;
  color: $primary__blue;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: dashed 1px #1b84ff;
  // height: 45px;
  // width: 50px;
  border-radius: 4px;
  font-weight: 600;
}

.strtgy__card__details {
  display: flex;
  flex-direction: column;
  padding: 0px 0px;

  span {
    font-size: 12px;
    color: $primary__mute;
  }

  h3 {
    font-size: 16px;
  }
}

.strtgy__card__sub__details {
  display: flex;
  flex-direction: column;
  padding: 5px 0px 0px 0px;
  margin-left: 10px;
  margin-right: 10px;
  border-top: 1px #ddd dashed;

  span {
    font-size: 13px;
    color: $primary__mute;
  }

  h3 {
    font-size: 16px;
  }
}

.strtgy__card__more {
  background-color: $primary__offblue;
  color: $primary__blue__bg !important;
  padding: 3px 3px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  transform: translateX(32px);
}

.sep__top {
  // border-top: solid 1px #ddd;
}

.vrtl__trd__badge {
  background-color: #fff7ec;
  color: #fda92b !important;
  border-radius: 4px;
  padding: 4px 5px;
  margin-left: 5px;
  font-size: 12px !important;
  font-weight: 600;
}

.lv__trd__badge {
  background-color: #e7fffb;
  color: #66dfc9 !important;
  border-radius: 4px;
  padding: 4px 5px;
  margin-left: 5px;
  font-size: 12px !important;
  min-width: 20px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
}

.strtgy__ttl {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 2px;
}

.btn__back {
  background-color: #eff0ff;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.strtgy__table__box {
  background-color: #fff;
  border: solid 1px $blue__off__bg;
  border-radius: 8px;
  padding-right: 10px;
}

.market__indicator {
  position: absolute;
  height: 10px;
  width: 10px;
  display: inline-flex;
  border-radius: 100%;
  right: 15px;
  top: 18px;
}

.market__indicator.active {
  background-color: $primary__red;
  animation: animate-pulse 3s linear infinite;
}

@keyframes animate-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 109, 74, 0.7), 0 0 0 0 rgba(255, 109, 74, 0.7);
  }

  40% {
    box-shadow: 0 0 0 10px rgba(255, 109, 74, 0),
      0 0 0 0 rgba(255, 109, 74, 0.7);
  }

  80% {
    box-shadow: 0 0 0 10px rgba(255, 109, 74, 0),
      0 0 0 10px rgba(255, 109, 74, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 109, 74, 0), 0 0 0 10px rgba(255, 109, 74, 0);
  }
}

.strtgy__table__box {
  ul {
    display: flex;

    li {
      list-style: none;
      padding-left: 15px;
      padding-right: 15px;
      color: $primary__mute;
      cursor: pointer;
      font-weight: 500;

      &:hover {
        border-bottom: solid 3px $primary__blue__bg;
      }

      span {
        padding: 15px 0px;
        display: block;
      }
    }

    li.active {
      border-bottom: solid 3px $primary__blue__bg;
      color: #000;
    }
  }
}

.btn_swipe_exit {
  right: 230px;
  top: 10px;

  .MuiSwitch-root {
    width: 120px;
    height: 45px;
    border-radius: 15px;
  }

  .MuiSwitch-switchBase {
    padding: 0px;
    top: 10px;
  }

  .Mui-checked + .MuiSwitch-track {
    background-color: $blue__off__bg !important;
    opacity: 1 !important;
    border-radius: 50px;
  }

  .MuiSwitch-track {
    border-radius: 30px !important;
  }

  .MuiSwitch-track::before {
    width: 120px !important;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-arrow-left-short" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/></svg>') !important;
    transform: rotate(180deg) !important;
    background-repeat: no-repeat;
  }

  .MuiSwitch-track::after {
    width: 120px !important;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-arrow-left-short" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/></svg>') !important;
    background-repeat: no-repeat;
  }

  .MuiSwitch-thumb {
    box-shadow: none;
    width: 25px !important;
    height: 25px !important;
    margin: 0px !important;
  }

  .MuiSwitch-input {
    top: 20px;
    width: 100px !important;
  }

  .Mui-checked {
    transform: translateX(90px) !important;
  }
}

#app-cover {
  display: table;
  width: 600px;
  margin: 80px auto;
  counter-reset: button-counter;
}

.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: 200px;
  height: 140px;
  box-sizing: border-box;
}

.button-cover {
  height: 100px;
  margin: 20px;
  background-color: #fff;
  box-shadow: 0 10px 20px -8px #c5d6d6;
  border-radius: 4px;
}

.button-cover:before {
  counter-increment: button-counter;
  content: counter(button-counter);
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  width: 140px;
  height: 38px;
  margin: 0 auto;
  overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 2px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #fff;
  transition: 0.3s ease all;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px $primary__blue__bg;
}

#button-1 .knobs:before {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 30px;
  height: 30px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: $primary__blue__bg;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-arrow-left-short" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/> </svg>') !important;
  transform: rotate(180deg) !important;
  background-position: center;
  background-repeat: no-repeat;
}

#button-1 .checkbox:checked + .knobs:before {
  content: '';
  left: 106px;
  background-color: #66dfc9;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-arrow-left-short" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/> </svg>') !important;
  transform: rotate(0deg) !important;
}

#button-1 .checkbox:checked ~ .layer {
  background-color: #e5fbf7;
  border: solid 1px #66dfc9;
}

#button-1 .checkbox ~ .layer::after {
  content: 'Swipe to Exit';
  font-size: 12px;
  padding-left: 15px;
  font-weight: 500;
}

#button-1 .checkbox:checked ~ .layer::after {
  content: 'Swipe to Enter';
  font-size: 12px;
  font-weight: 500;
  padding-right: 20px;
  padding-left: 0px !important;
}

#button-1 .knobs,
#button-1 .knobs:before,
#button-1 .layer {
  transition: 0.3s ease all;
}

.btn__exit {
  background-color: $primary__red;
  color: $primary__white;
  right: 180px;
  padding: 10px;
  border-radius: 100%;
  border: none;
  top: 10px;
}

.ant-table-thead > tr > th {
  background-color: $primary__white;
  font-weight: 600;
}

.ant-table-tbody > tr > td {
  color: rgb(var(--color-text-primary));
}

.cstm__dt__pick {
  right: 0;
  top: 10px;

  .ant-picker {
    border-radius: 6px !important;
  }

  .ant-picker-active-bar {
    display: none;
  }

  .ant-picker-range {
    width: 40px !important;
    transition: all 0.7s;
    .ant-picker-input {
      z-index: -1;
      margin-right: -4px;
    }
  }

  .ant-picker-range.ant-picker-focused {
    width: 200px !important;

    .ant-picker-input {
      z-index: unset;
      margin-right: unset;
    }
  }

  .ant-picker-input-active {
    // input{
    //   width: 50px;
    // }
    input::placeholder {
      opacity: 1;
      color: #000;
    }
  }
}

.tabs-content {
  display: none;
}

.tab-content-active {
  padding-left: 0px;
  padding-right: 0px;
}

.close__order__data {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $primary__mute;
  padding-top: 35px;
  padding-bottom: 35px;

  span {
    color: #ccc;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.close__order__data.inner {
  padding: 50px;
}

.pnl_charge {
  color: $primary__green;
}

.ant-table-expanded-row {
  th {
    background-color: $blue__off__bg !important;
    padding: 8px 8px;
    font-size: 13px;
  }
}

.ant-tag-red {
  position: relative !important;
  padding-left: 20px;
  background-color: transparent;
  border: none;

  &::after {
    content: '';
    background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="red" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M862 465.3h-81c-4.6 0-9 2-12.1 5.5L550 723.1V160c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v563.1L255.1 470.8c-3-3.5-7.4-5.5-12.1-5.5h-81c-6.8 0-10.5 8.1-6 13.2L487.9 861a31.96 31.96 0 0 0 48.3 0L868 478.5c4.5-5.2.8-13.2-6-13.2z"></path></svg>') !important;
    height: 15px;
    width: 15px;
    position: absolute;
    left: 0;
    display: block;
    top: 2px;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.ant-table-cell {
  font-size: 14px;
  padding: 13px 16px !important;
}

.ant-tag {
  font-size: 14px;
}

.ant-tag-green {
  position: relative !important;
  padding-left: 20px;
  background-color: transparent;
  border: none;

  &::after {
    content: '';
    background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="green" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path></svg>') !important;
    height: 15px;
    width: 15px;
    position: absolute;
    left: 0;
    display: block;
    top: 2px;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

// .ant-spin-nested-loading{
//   .ant-table-thead{
//     tr{
//       th{
//         background-color: transparent;
//       }
//     }
//   }
// }
.dark-theme {
  .mobile__sticky__nav {
    ul {
      background-color: #262831;

      li {
        a {
          svg {
            color: #fff;
          }
        }
      }
    }
  }
}

.mobile__sticky__nav {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 999;
  box-shadow: 2px 4px 6px 2px #999;

  ul {
    background-color: #262831;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px;

    li {
      list-style: none;
      padding: 5px;

      a.active {
        background-color: $primary__blue__bg;
        color: $primary__white;

        svg {
          color: $primary__white;
        }
      }

      a {
        padding: 5px 10px;
        border-radius: 4px;
        display: block;

        svg {
          color: #fff;
          font-size: 18px;
        }
      }
    }
  }
}

.strtgy__table__drpdwn {
  display: none;
}

.bg__white {
  background-color: $primary__white;
  border: solid 1px #f1f1f4;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
}

.strategy__sml__details {
  ul {
    padding-left: 0;
    display: flex;

    li {
      list-style: none;
      display: flex;
      border: solid 1px $blue__off__bg;
      font-size: 13px;
      padding: 5px 7px;
      margin-left: 5px;
      margin-right: 5px;
      border-radius: 6px;
      font-weight: 600;

      span {
        color: $primary__mute;
        margin-right: 5px;
      }
    }
  }
}

.strategy__sml__btns {
  padding-left: 0px;
  display: flex;
  align-items: center;
  justify-content: end;

  li {
    list-style: none;
    display: flex;
    margin-right: 2px;
    margin-left: 2px;

    a {
      padding: 5px;
      background-color: #eff0ff;
      border-radius: 100%;
      display: block;
      height: 35px;
      width: 35px;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    button {
      padding: 5px;
      background-color: #eff0ff;
      border-radius: 100%;
      display: block;
      height: 35px;
      width: 35px;
      align-items: center;
      justify-content: center;
      display: flex;
      border: none;

      svg {
        color: $primary__blue__bg;
      }
    }
  }
}

.cstm__scroll {
  display: flex;
  overflow-x: auto;
  flex-wrap: initial;
  padding-bottom: 15px;
  max-width: 100%;
}

.strategy__sml__details__bellow {
  ul {
    padding-left: 0;
    display: flex;

    li {
      list-style: none;
      display: flex;
      font-size: 14px;
      padding: 7px;
      // border-right: solid 1px #efefef;
      width: 20%;
      position: relative;
      align-items: center;
      justify-content: center;
      font-weight: 600;

      &:nth-child(1) {
        width: 26%;
      }

      &:nth-last-child(1) {
        border-right: none;
      }

      span {
        color: $primary__mute;
        padding-right: 5px;
      }
    }
  }
}

.chart__main {
  height: 90vh;
  margin-top: 70px;
  margin-left: 70px;
  width: 94vw;
}

.cstm__dt__pick {
  .ant-picker-range-separator {
    display: none;
  }

  .ant-picker input {
    font-size: 13px !important;
  }
}

.strategy__sml__details__bellow.market__active {
  border: solid 1px #efefef;
  border-radius: 6px;
  background-color: #fff;
}

.primary__text__blue {
  color: $primary__blue__bg;
}

.primary__text__red {
  color: $primary__red;
}

.primary__text__green {
  color: $primary__green;
}

.primary__bg__ofgreen {
  // background-color: $primary__bg__ofgreen;
  background-color: #e7fffb;
}

.primary__bg__ofred {
  background-color: $primary__bg__ofred;
}

.text__semibold {
  font-weight: 600;
}

.text__normal {
  font-weight: 500;
}

.empty__bx {
  // background-color: #fff;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 16px;
    color: $primary__mute;
  }

  h4 {
    font-size: 14px;
    color: $primary__mute;
    font-weight: normal;
  }

  .btn__add__strategy {
    background-color: transparent;
    border: solid 1px $primary__blue__bg;
    height: 35px;
    width: 35px;
    border-radius: 4px;
    margin-top: 30px;
    margin-bottom: 30px;

    &:hover {
      background-color: $primary__blue__bg;

      svg {
        fill: #fff;
      }
    }
  }
}

.strategy__tbl__data {
  table {
    td {
      .ant-tag-volcano {
        color: $primary__green !important;
        background-color: transparent;
        border: none;
      }

      .ant-tag-geekblue {
        color: $primary__red !important;
        background-color: transparent;
        border: none;
      }

      // a {
      //   color: $primary__blue__bg !important;
      // }
    }
  }
}

.btn__add__virtualTrade {
  background-color: transparent;
  border: none;
  color: $primary__blue__bg;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
}
.btn__print {
  background-color: $primary__green;
  border: none;
  color: $primary__white;
  margin-left: 10px;
  margin-right: 10px;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.btn__print.deactive {
  background-color: var(--color-component-gray-1);
  color: #999;
}

.btn__delete.deactive {
  background-color: var(--color-component-gray-1);
  color: #999;
}

.btn__delete {
  background-color: $primary__red;
  border: none;
  color: $primary__white;
  margin-left: 0px;
  margin-right: 10px;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 6px;
}

.cstm__dt__pick {
  .ant-picker {
    padding: 4px;
    &.ant-picker-range {
      &.ant-picker-focused {
        gap: 5px;
        padding: 4px 4px 4px 11px;
      }
    }
  }
  .ant-picker-suffix,
  .ant-picker-clear {
    background-color: #e9f3ff;
    padding: 8px;
    border-radius: 4px;
    color: $primary__blue;
    margin-left: 0px;
    margin-right: 0px;
    cursor: pointer;
  }

  .ant-picker-range {
    padding-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-picker-range.ant-picker-focused {
    .ant-picker-suffix,
    .ant-picker-clear {
      margin-right: 0px;
    }
  }
  .ant-picker-clear {
    margin-right: -7px !important;
  }
}

.trd__data__head {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  border-bottom: solid 1px var(--color-border-2);
  background-color: rgb(var(--color-base-2));
  padding: 13px;
  position: relative;

  .coll {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;

    &::after {
      content: '';
      height: 20px;
      width: 1px;
      background-color: #efefef;
      position: absolute;
      top: 0px;
      right: 0px;
      display: flex;
    }

    &:nth-child(1) {
      width: 5%;
    }

    &:nth-child(2) {
      width: 10%;
    }

    &:nth-child(3) {
      width: 10%;
    }

    &:nth-child(4) {
      width: 15%;
    }

    &:nth-child(5) {
      width: 13%;
    }

    &:nth-child(6) {
      width: 7%;
      text-align: center;
    }

    &:nth-child(7) {
      width: 5%;
      text-align: center;
    }

    &:nth-child(8) {
      width: 10%;
      text-align: center;
    }

    &:nth-child(9) {
      width: 8%;
    }

    &:nth-child(10) {
      width: 10%;
    }

    &:nth-child(11) {
      width: 7%;
    }

    &:nth-last-child(1) {
      &::after {
        display: none;
      }
    }
  }
}
.dark {
  .trd__data__head {
    background-color: var(--color-component-gray-1);
  }
}

.trd__data__body {
  display: flex;
  flex-direction: row;

  .coll.arrow {
    position: relative;

    span {
      position: relative;
      right: 0px;
    }
  }

  .coll {
    &:nth-child(1) {
      width: 5%;
    }

    &:nth-child(2) {
      width: 10%;
    }

    &:nth-child(3) {
      width: 10%;
    }

    &:nth-child(4) {
      width: 15%;
    }

    &:nth-child(5) {
      width: 13%;
    }

    &:nth-child(6) {
      width: 7%;
      text-align: center;
    }

    &:nth-child(7) {
      width: 5%;
      text-align: center;
    }

    &:nth-child(8) {
      width: 10%;
      text-align: center;
    }

    &:nth-child(9) {
      width: 8%;
    }

    &:nth-child(10) {
      width: 10%;
    }

    &:nth-child(11) {
      width: 7%;
    }
  }
}

.tbl__hd.mobile {
  display: flex;
  flex-direction: row;

  .coll {
    font-size: 13px;
    flex: 0 0 auto;
    font-weight: 500;

    &:nth-child(1) {
      width: 8%;
    }

    &:nth-child(2) {
      width: 28%;
    }

    &:nth-child(3) {
      width: 15%;
    }

    &:nth-child(4) {
      width: 26%;
    }

    &:nth-child(5) {
      width: 17%;
    }
  }
}

.tbl__hd.mobile.close__order {
  display: flex;
  flex-direction: row;

  .coll {
    font-size: 13px;
    flex: 0 0 auto;
    font-weight: 500;

    &:nth-child(1) {
      width: 35%;
    }

    &:nth-child(2) {
      width: 25%;
    }

    &:nth-child(3) {
      width: 15%;
    }

    &:nth-child(4) {
      width: 25%;
    }
  }
}

.tbl__body {
  display: flex;
  flex-direction: row;
  width: 100%;

  .coll.pnl {
    position: relative;

    span {
      position: relative;
      right: 0px;
    }
  }

  .coll {
    font-size: 13px;
    flex: 0 0 auto;

    &:nth-child(1) {
      width: 8%;
    }

    &:nth-child(2) {
      width: 30%;
    }

    &:nth-child(3) {
      width: 15%;
    }

    &:nth-child(4) {
      width: 30%;
    }

    &:nth-child(5) {
      width: 17%;
    }
  }
}

.tbl__body.close__order {
  display: flex;
  flex-direction: row;
  width: 100%;

  .coll.pnl {
    position: relative;

    span {
      position: absolute;
      right: 25px;
    }
  }

  .coll {
    font-size: 13px;
    flex: 0 0 auto;

    &:nth-child(1) {
      width: 35%;
    }

    &:nth-child(2) {
      width: 28%;
    }

    &:nth-child(3) {
      width: 15%;
    }

    &:nth-child(4) {
      width: 25%;
    }
  }
}

.tbl__body__inner {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fff;

  .coll {
    font-size: 13px;
    padding: 5px 10px;
    border-bottom: solid 1px #ddd;
    font-weight: 500;

    &:nth-child(1) {
      width: 8%;
    }

    &:nth-child(2) {
      width: 20%;
    }

    &:nth-child(3) {
      width: 27%;
    }

    &:nth-child(4) {
      width: 15%;
    }

    &:nth-child(5) {
      width: 15%;
    }

    &:nth-child(6) {
      width: 15%;
    }
  }
}

.tbl__body__inner.close__order {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fff;

  .coll {
    font-size: 13px;
    padding: 5px 10px;
    border-bottom: solid 1px #ddd;
    font-weight: 500;

    &:nth-child(1) {
      width: 17%;
    }

    &:nth-child(2) {
      width: 17%;
    }

    &:nth-child(3) {
      width: 17%;
    }

    &:nth-child(4) {
      width: 17%;
    }

    &:nth-child(5) {
      width: 16%;
    }

    &:nth-child(6) {
      width: 16%;
    }
  }
}

.tbl__body__inner__data {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fff;

  .coll {
    font-size: 13px;
    padding: 5px 10px;

    &:nth-child(1) {
      width: 8%;
    }

    &:nth-child(2) {
      width: 20%;
    }

    &:nth-child(3) {
      width: 27%;
    }

    &:nth-child(4) {
      width: 15%;
    }

    &:nth-child(5) {
      width: 15%;
    }

    &:nth-child(6) {
      width: 15%;
    }
  }
}

.tbl__body__inner__data.close__order {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fff;

  .coll {
    font-size: 13px;
    padding: 5px 10px;

    &:nth-child(1) {
      width: 17%;
    }

    &:nth-child(2) {
      width: 17%;
    }

    &:nth-child(3) {
      width: 17%;
    }

    &:nth-child(4) {
      width: 17%;
    }

    &:nth-child(5) {
      width: 16%;
    }

    &:nth-child(6) {
      width: 16%;
    }
  }
}

.tbl__hd.mob__strategy {
  display: flex;
  flex-direction: row;

  .coll {
    font-size: 13px;
    flex: 0 0 auto;
    font-weight: 500;

    &:nth-child(1) {
      width: 35%;
    }

    &:nth-child(2) {
      width: 15%;
    }

    &:nth-child(3) {
      width: 25%;
    }

    &:nth-child(4) {
      width: 25%;
    }
  }
}

.tbl__body.mob__strategy {
  display: flex;
  flex-direction: row;
  width: 100%;

  .coll.pnl {
    position: relative;

    span {
      position: absolute;
      right: 25px;
    }
  }

  .coll {
    font-size: 13px;
    flex: 0 0 auto;

    &:nth-child(1) {
      width: 37%;
    }

    &:nth-child(2) {
      width: 15%;
    }

    &:nth-child(3) {
      width: 25%;
    }

    &:nth-child(3) {
      width: 25%;
    }
  }
}

.tbl__body__inner__data.mob__strategy {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fff;

  .coll {
    font-size: 13px;
    padding: 5px 10px;

    &:nth-child(1) {
      width: 10%;
    }

    &:nth-child(2) {
      width: 35%;
    }

    &:nth-child(3) {
      width: 15%;
    }

    &:nth-child(4) {
      width: 20%;
    }

    &:nth-child(5) {
      width: 20%;
    }
  }
}

.tbl__body__inner.mob__strategy {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fff;

  .coll {
    font-size: 13px;
    padding: 5px 10px;
    border-bottom: solid 1px #ddd;
    font-weight: 500;

    &:nth-child(1) {
      width: 10%;
    }

    &:nth-child(2) {
      width: 35%;
    }

    &:nth-child(3) {
      width: 15%;
    }

    &:nth-child(4) {
      width: 20%;
    }

    &:nth-child(5) {
      width: 20%;
    }
  }
}

.sidebar__area > img {
  padding: 2px;
  margin-top: 10px;
}

.tbl__body__inner.close__order.report {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fff;

  .coll {
    font-size: 13px;
    padding: 5px 10px;
    border-bottom: solid 1px #ddd;
    font-weight: 500;

    &:nth-child(1) {
      width: 25%;
    }

    &:nth-child(2) {
      width: 25%;
    }

    &:nth-child(3) {
      width: 25%;
    }

    &:nth-child(4) {
      width: 25%;
    }
  }
}

.tbl__body__inner__data.close__order.report {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fff;

  .coll {
    font-size: 13px;
    padding: 5px 10px;

    &:nth-child(1) {
      width: 25%;
    }

    &:nth-child(2) {
      width: 25%;
    }

    &:nth-child(3) {
      width: 25%;
    }

    &:nth-child(4) {
      width: 25%;
    }
  }
}

.tbl__body__inner.mob__strategy.report {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fff;

  .coll {
    font-size: 13px;
    padding: 5px 10px;
    border-bottom: solid 1px #ddd;
    font-weight: 500;

    &:nth-child(1) {
      width: 33.33%;
    }

    &:nth-child(2) {
      width: 33.33%;
    }

    &:nth-child(3) {
      width: 33.33%;
    }
  }
}

.tbl__body__inner__data.mob__strategy.report {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fff;

  .coll {
    font-size: 13px;
    padding: 5px 10px;

    &:nth-child(1) {
      width: 33.33%;
    }

    &:nth-child(2) {
      width: 33.33%;
    }

    &:nth-child(3) {
      width: 33.33%;
    }
  }
}

.tbl__hd.mobile.broker {
  display: flex;
  flex-direction: row;

  .coll {
    font-size: 13px;
    flex: 0 0 auto;
    font-weight: 500;

    &:nth-child(1) {
      width: 33.33%;
    }

    &:nth-child(2) {
      width: 30.33%;
    }

    &:nth-child(3) {
      width: 33.33%;
    }
  }
}

.tbl__body.broker {
  display: flex;
  flex-direction: row;
  width: 100%;

  .coll.pnl {
    position: relative;

    span {
      position: relative;
      right: 0px;
    }
  }

  .coll {
    font-size: 13px;
    flex: 0 0 auto;

    &:nth-child(1) {
      width: 35.33%;
    }

    &:nth-child(2) {
      width: 32.33%;
    }

    &:nth-child(3) {
      width: 33.33%;
    }
  }
}

.tbl__body__inner.broker {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fff;

  .coll {
    font-size: 13px;
    padding: 5px 10px;
    border-bottom: solid 1px #ddd;
    font-weight: 500;

    &:nth-child(1) {
      width: 33.33%;
    }

    &:nth-child(2) {
      width: 25.33%;
    }

    &:nth-child(3) {
      width: 41.33%;
    }
  }
}

.tbl__body__inner__data.broker {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fff;

  .coll {
    font-size: 13px;
    padding: 5px 10px;

    &:nth-child(1) {
      width: 33.33%;
    }

    &:nth-child(2) {
      width: 25.33%;
    }

    &:nth-child(3) {
      width: 40.33%;
    }
  }
}

.show_more {
  color: $primary__blue__bg;
  font-size: 13px;
  font-weight: 500;
}

.show_more {
  display: none;
}

.mob_sect {
  display: none;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.close__order.accordion {
  .accordion-body {
    padding: 0px;
  }

  .accordion-button {
    padding: 7px 10px;
    border-radius: 0px;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.sep__ln {
  // border-bottom: solid 1px #ddd;
  // padding-bottom: 10px;
}

.rprt__dt {
  display: flex;
  font-size: 13px;
  color: #666;
  margin-top: 5px;

  span {
    margin-right: 5px;
  }
}

.txt-xs {
  font-size: 13px;
  color: $primary__mute;
}

.btn__create__strategy {
  background-color: $primary__blue__bg;
  color: #fff;
  // border-radius: 30px;
  border-radius: 6px;
  border: none;
  font-size: 13px;
  padding: 7px 15px;
  // margin-right: 10px;
}

.btn-action {
  border: solid 1px #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  border-radius: 6px;
  font-size: 16px;

  &.act__btn__view {
    color: $primary__blue__bg;
    width: 30px;
    height: 30px;

    button {
      color: $primary__blue__bg !important;

      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  &.act__btn__delete {
    color: $primary__red !important;
    width: 30px;
    height: 30px;
  }

  &.act__btn__delete {
    color: $primary__red !important;
    width: 30px;
    height: 30px;

    button {
      color: $primary__red !important;

      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  &.act__btn__close {
    color: $primary__red !important;
    width: 30px;
    height: 30px;

    button {
      color: $primary__red !important;

      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  &.act__btn__play {
    color: $primary__green !important;
    width: 30px;
    height: 30px;

    button {
      color: $primary__green !important;

      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  &.act__btn__pause {
    color: #f28649 !important;
    width: 30px;
    height: 30px;

    button {
      color: #f28649 !important;

      svg {
        height: 18px;
        width: 18px;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.strategy_tbl {
  .Expired {
    color: $primary__red !important;
  }

  .Rejected {
    color: $primary__red !important;
  }

  .Processing {
    color: #cb680d !important;
  }

  .Running {
    color: #43a233 !important;
  }

  .Active {
    color: #4074f9 !important;
  }
}

.trm__and__cond {
  padding-left: 15px;
  margin-top: 10px;

  li {
    margin-bottom: 5px;
    color: $primary__mute;
    font-size: 14px;
  }
}

.txt-regular {
  font-size: 15px;
}

.notifucation__badge {
  background-color: $primary__red;
  position: absolute;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  font-size: 11px;
  top: 0px;
  right: 0px;
}

.invalid_file_sect {
  background: #ffe6e6;

  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
  color: red;

  span {
    padding: 10px;
    display: block;
  }
}

.modalPending {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;

  span {
    font-size: 50px;
    color: #ff9e5e;
  }
}

.modalDenied {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;

  span {
    font-size: 50px;
    color: red;
  }
}

// UI Refinements
.btn__collapse {
  background-color: #fff;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px #fff;
  position: absolute;
  right: -14px;
  top: 28px;
  box-shadow: 5px 0px 10px 0px #ddd;
}

.toggle-on .btn__collapse {
  transform: rotate(180deg);
  box-shadow: -5px 0px 10px 0px #ddd;
}

.toggle-on .left__bar {
  margin-left: 200px;
  transition: all 0.3s;
}

.sidebar__area {
  width: 50px;
  transition: all 0.3s;
}

.toggle-on .sidebar__area {
  width: 150px;
  transition: all 0.3s;
}

#root {
  padding-top: 0px;
}

.fs-7 {
  font-size: 0.8rem !important;
}

.time_update {
  margin-right: 10px;
  font-weight: 500;
  color: #99a1b7 !important;
}

.bx_shadow {
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
}

.sub__ttl {
  font-size: 12px !important;
  font-weight: 500;
  color: #99a1b7 !important;
}

.fs-26 {
  font-size: 24px;
}

.fs-18 {
  font-size: 18px;
}

.bg__icon {
  background-color: #f5f5f5;
  padding: 7px;
  border-radius: 10px;

  img {
    height: 32px;
    width: auto;
  }
}

.strgy_ttl_box {
  background-color: $primary__offblue;
  color: $primary__blue;
  padding: 8px 15px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s;
  margin-bottom: 10px;

  &:hover {
    background-color: $primary__blue__bg;
    color: #fff !important;
  }
}

.small__container {
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
}

.tbl__badge {
  font-size: 13px;
  text-transform: capitalize;
  padding: 2px 7px;
  min-width: 60px;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.dash_separate {
  border-bottom: dashed 1px #ddd;
  margin-top: 15px;
}

.sml__grd {
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow: hidden;
}

.user__info__box {
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border: solid 1px #f1f1f4;
  padding: 8px 20px;
  border-radius: 10px;
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
}

.user__dp {
  img {
    border-radius: 10px;
  }
}

.user__info {
  span {
    font-size: 14px;
    color: #99a1b7 !important;
  }
}

.grd__info {
  span {
    font-size: 14px;
    color: #99a1b7 !important;
  }

  h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
  }
}

.additional__info {
  margin-top: 15px;
  margin-left: 0;
  margin-right: 0;
}

.grd__ico {
  height: 40px;
  width: 40px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 18px;
  background-color: #e9f3ff;

  svg {
    fill: $primary__blue;
  }
}

.User__Details__Card {
  border: solid 1px #f1f1f4;
  border-radius: 4px;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);

  .Details__Card__head {
    padding: 15px 20px;
    border-bottom: solid 1px #f1f1f4;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      display: inline-flex;
    }

    button {
      display: flex;
    }
  }

  .Details__Card__Body {
    padding: 10px 20px;

    label {
      margin-bottom: 0px;
      font-size: 15px;
      color: #99a1b7 !important;
      font-weight: 500;
    }

    // span {
    //   margin-bottom: 0px;
    //   font-size: 15px;
    //   font-weight: 500;
    // }
  }
}

.btn_default {
  background-color: #e9f3ff;
  color: #1b84ff;
  padding: 10px 10px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.btn_default_active {
  background-color: #1b84ff;
  color: #fff;
  padding: 8px 15px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.2s;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.btn_default:hover {
  background-color: #1b84ff;
  color: #fff;
}

.separate__between {
  border-bottom: dashed 1px #ddd;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
}

.small__container {
  .nav.nav-tabs {
    border-radius: 10px;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
    border: solid 1px #eff0ff;
    margin-top: 20px;

    .nav-link.active {
      background-color: transparent !important;
      border: none !important;
      border-bottom: solid 2px $primary__blue !important;
      color: #000;
      font-weight: 500 !important;
    }

    .nav-link {
      color: $primary__mute;
      font-weight: 500;
      border: none !important;
    }

    .nav-item {
      isolation: unset !important;

      button {
        margin-top: 0px;
        padding: 15px;
        isolation: unset !important;
        outline: none !important;
      }
    }
  }
}

.setting_grd {
  display: flex;
  flex-direction: column;

  p {
    color: #000;
    font-weight: 500;
    font-size: 14px;
  }

  span {
    color: #99a1b7 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}

.brockerCard__body {
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border: solid 1px #f1f1f4;
  border-radius: 8px;
  padding: 0px;
  overflow: hidden;

  .brocker__img {
    height: 40px;
    border-radius: 0px;
    width: 40%;
    padding: 5px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }

  .brocker__info {
    border-top: dashed 1px #ddd;
    width: 100%;
    padding: 8px;
    text-align: left;
    min-height: 45px;
  }

  .connect__button {
    padding-bottom: 15px;
  }
}

.broker_page {
  margin-top: 70px;
}

.BrockerTable__container {
  background-color: #15171c;
  box-shadow: none;
}

.badge.bg-success {
  background-color: #e7fffb !important;
  padding: 8px 12px;
  font-weight: normal;
  color: #17c653;
  font-size: 13px;
  min-width: 105px;
  border-radius: 4px;
}

.connect__button.mobile {
  display: none;
}

.badge.bg-danger {
  background-color: #fff0f3 !important;
  padding: 10px 15px;
  font-weight: normal;
  color: #f8285a;
  font-size: 13px;
  border-radius: 4px;
  min-width: 105px;
}

.view__button {
  background-color: #e9f3ff;
  color: #1b84ff;
  padding: 4px 15px;
}

.view__button:hover {
  background-color: #1b84ff;
  color: #fff;
  transition: all 0.7s;
}

.brockerCards {
  width: 100%;
  padding: 4px;
  border-radius: 10px;
  justify-content: space-between;
}

.textBColor {
  font-size: 14px;
  color: $primary__blue !important;
}

.brockerCard__body {
  .brocker__info {
    .brocker__name {
      align-items: center;
      display: flex;
      font-weight: 600;
    }
  }
}

.brockerCard__body {
  .brocker__img {
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.card_hidden_btn {
  position: absolute;
  right: 3px;
  top: 15px;
  width: 20px;
  height: 20px;
  background-color: $primary__blue;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  transition: all 0.2s;
  transform: translateX(23px);
  cursor: pointer;
  opacity: 0;

  svg {
    font-size: 11px;
    color: #fff;
  }
}

.strtgy__card {
  &:hover {
    .card_hidden_btn {
      transform: translateX(12px);
      opacity: 1;
    }
  }
}

.broker_pg_head {
  border-radius: 10px;
  padding: 20px;
}

#v-pills-tab {
  display: flex;

  .step__guide {
    &:nth-last-child(1) {
      &::after {
        display: none;
      }
    }
  }

  .nav-link {
    font-size: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 2px 0px;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;

    &:nth-last-child(1) {
      &::after {
        display: none;
      }
    }

    &::after {
      content: '';
      height: 25px;
      border-right: dashed 1px #999;
      position: absolute;
      width: 2px;
      display: flex;
      left: 16px;
      top: 37px;
    }

    label {
      background-color: #e9f3ff;
      width: 35px;
      height: 35px;
      padding: 10px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
    }
  }

  .nav-link.active {
    background-color: transparent !important;

    label {
      background-color: $primary__blue;
      color: #fff;
    }

    span {
      color: $primary__blue;
    }
  }
}

.step__guide {
  margin-bottom: 15px;
  align-items: center;
  margin-top: 15px;
  position: relative;

  &::after {
    content: '';
    height: 25px;
    border-right: dashed 1px #999;
    position: absolute;
    width: 2px;
    display: flex;
    left: 16px;
    top: 37px;
  }

  span {
    background-color: $primary__blue;
    color: #fff;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
  }

  a {
    margin: 0;
    padding: 5px;
    line-height: 20px;
    font-size: 15px;
    position: relative;
  }
}

.mleSje {
  img {
    max-width: 100%;
    height: auto;
  }

  max-height: calc(100vh - 100px) !important;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 320px) and (max-width: 576px) {
  .dark-theme {
    .ant-table-expanded-row {
      th {
        background-color: #15171c !important;
      }
    }
    .dropdown-toggle.btn.btn-primary {
      background-color: #172331 !important;
    }

    .strtgy__table__box {
      margin: 0px;
      margin-top: 15px !important;
    }

    .tbl_data {
      margin: 0px;
    }

    .tbl__hd {
      background-color: #15171c !important;
      color: #fff;
    }

    .portfolio__list__card {
      .sep__line {
        background-color: #15171c;
      }
    }

    .show_more {
      background-color: #172331;
    }

    .left__bar {
      .strgy_ttl {
        color: #fff;
      }
    }

    .brockerCard__body {
      .brocker__img {
        width: 100%;
      }
    }

    .close__order.accordion {
      .accordion-button {
        background-color: #15171c;
        color: #ddd;

        &::after {
          filter: brightness(0) saturate(100%) invert(91%) sepia(45%)
            saturate(1%) hue-rotate(175deg) brightness(106%) contrast(73%);
        }
      }

      .accordion-item {
        border-color: #373942;
      }

      .tbl__body__inner.close__order {
        background-color: #23262d;
        color: #ddd;
      }

      .tbl__body__inner__data.close__order {
        background-color: #15171c;
        color: #ddd;
      }

      .tbl__body__inner.close__order .coll {
        border-color: #333;
      }
    }

    .cstm__accordion {
      .accordion-item {
        border-color: #222;
      }

      .accordion-button {
        background-color: #15171c;
        color: #ddd;
      }

      .tbl__body__inner.broker {
        background-color: #1f2127;
        color: #ddd;

        .coll {
          border-color: #333;
        }
      }

      .tbl__body__inner__data {
        background-color: #1f2127;
        color: #ddd;
      }
    }
  }

  .instruction {
    .instructionS {
      position: relative !important;
      height: auto !important;
      width: 100% !important;
    }

    .mleSje {
      margin-left: 0px !important;
      max-height: 100vh !important;
      margin-bottom: 20px !important;
      overflow: scroll !important;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .small__container {
    width: 100%;
    margin-top: 15px;
  }

  .user__info__box {
    width: 100%;
  }

  .user__dp {
    width: 100px;
    margin: 10px auto;
  }

  .user__info {
    text-align: center;
  }

  .sml__grd {
    margin-bottom: 10px;
  }

  .grd__info {
    h4 {
      font-size: 13px;
    }

    span {
      font-size: 12px;
    }
  }

  .User__Details__Card {
    .Details__Card__Body {
      label {
        font-size: 14px;
      }

      span {
        font-size: 13px;
      }
    }
  }

  .brockerCards {
    flex-direction: column !important;
  }

  .brockerCard__body {
    .brocker__img {
      width: 100% !important;
    }

    .connect__button {
      padding-bottom: 0px !important;
      margin-top: 10px;
    }
  }

  .top__bar {
    margin-left: 0;
    margin-top: 50px;
    margin-right: 0;
  }

  .btn__print {
    display: none;
  }

  .connect__button.dsktp {
    display: none;
  }

  .connect__button.mobile {
    width: 100%;
    margin-top: 0px;
    display: block;

    .btn_default {
      width: 100%;
      border-radius: 0px;
    }
  }

  .brdr_sep {
    border-left: dashed 1px $primary__blue;
  }

  .ant-picker-panel-container .ant-picker-panels {
    flex-direction: column !important;
  }

  .strtgy__ttl {
    margin-top: 0px !important;
  }

  .badge.bg-success {
    padding: 5px 5px !important;
    min-width: auto !important;
  }

  .badge.bg-danger {
    padding: 5px 5px !important;
    min-width: auto !important;
  }

  .view__button {
    background-color: #e9f3ff;
    color: #1b84ff;
    padding: 2px 8px;
  }

  .rprt__dt {
    flex-direction: column;
    font-size: 12px;
  }

  .tbl__body__inner {
    .coll:nth-child(2) {
      width: 15%;
    }
  }

  .tbl__body__inner {
    .coll:nth-child(3) {
      width: 22%;
    }
  }

  .tbl__body__inner {
    .coll {
      padding: 5px 8px;
    }
  }

  .tbl__body__inner__data {
    .coll {
      padding: 5px 8px;
    }
  }

  .tbl__body__inner__data .coll:nth-child(3) {
    width: 22%;
  }

  .tbl__body__inner .coll:nth-child(5) {
    width: 12%;
  }

  .tbl__body__inner__data .coll:nth-child(2) {
    width: 15%;
  }

  .tbl__body__inner__data .coll:nth-child(5) {
    width: 13%;
  }

  .tbl__hd.mobile.close__order .coll:nth-child(1) {
    width: 20%;
  }

  .tbl__hd.mobile.close__order .coll:nth-child(2) {
    width: 12%;
  }

  .tbl__hd.mobile.close__order .coll:nth-child(5) {
    width: 22%;
  }

  .tbl__body.close__order .coll:nth-child(1) {
    width: 22%;
  }

  .tbl__body.close__order .coll:nth-child(2) {
    width: 12%;
  }

  .tbl__body.close__order .coll:nth-child(3) {
    width: 18%;
  }

  .tbl__body__inner.close__order .coll:nth-child(1) {
    width: 20%;
  }

  .tbl__body__inner.close__order .coll:nth-child(2) {
    width: 20%;
  }

  .tbl__body__inner.close__order .coll:nth-child(3) {
    width: 20%;
  }

  .tbl__body__inner.close__order .coll:nth-child(4) {
    width: 20%;
  }

  .tbl__body__inner.close__order .coll:nth-child(5) {
    width: 20%;
  }

  .tbl__body__inner__data.close__order .coll:nth-child(1) {
    width: 20%;
  }

  .tbl__body__inner__data.close__order .coll:nth-child(2) {
    width: 20%;
  }

  .tbl__body__inner__data.close__order .coll:nth-child(3) {
    width: 20%;
  }

  .tbl__body__inner__data.close__order .coll:nth-child(4) {
    width: 20%;
  }

  .tbl__body__inner__data.close__order .coll:nth-child(5) {
    width: 20%;
  }

  .tbl__hd.mobile.close__order.trade .coll:nth-child(1) {
    width: 25%;
  }

  .tbl__hd.mobile.close__order.trade .coll:nth-child(2) {
    width: 26%;
  }

  .tbl__hd.mobile.close__order.trade .coll:nth-child(3) {
    width: 15%;
  }

  .tbl__hd.mobile.close__order.trade .coll:nth-child(4) {
    width: 15%;
  }

  .tbl__body.close__order.trade .coll:nth-child(1) {
    width: 27%;
  }

  .tbl__body.close__order.trade .coll:nth-child(2) {
    width: 26%;
  }

  .tbl__body.close__order.trade .coll:nth-child(3) {
    width: 17%;
  }

  .tbl__body.close__order.trade .coll:nth-child(4) {
    width: 15%;
  }

  .tbl__body.close__order.trade .coll:nth-child(5) {
    width: 15%;
    text-align: left;
  }

  .tbl__body.mob__strategy .coll:nth-child(3) {
    width: 28%;
  }

  .tbl__hd.mobile.close__order .coll:nth-child(3) {
    width: 22%;
  }

  .tbl__body.close__order.vt .coll:nth-child(2) {
    width: 15%;
  }

  .tbl__hd.mobile.close__order.vt .coll:nth-child(2) {
    width: 15%;
  }

  .tbl__body.close__order.vt .coll:nth-child(4) {
    width: 28%;
  }

  .tbl__hd.mobile.close__order.virtaul .coll:nth-child(3) {
    width: 17%;
  }

  .tbl__body.close__order.virtaul .coll:nth-child(3) {
    width: 21%;
  }

  .tbl__hd.mobile.close__order .coll:nth-child(4) {
    width: 23%;
  }

  .mb__spc {
    padding-bottom: 50px !important;
  }

  .cstm__btm__spc {
    padding-bottom: 5px;
  }

  .mt__spc {
    margin-bottom: 10px;
  }

  .leftDrawers.cstm {
    .ant-drawer-body {
      padding: 10px 15px;
    }

    .ant-form-item {
      margin-bottom: 5px;
    }

    margin-top: 60px;
  }

  .leftDrawers .profileInf h5 {
    font-size: 15px;
  }

  .btn__logout {
    display: none;
  }

  .cstm__tp__space {
    margin-top: 75px !important;
    margin-bottom: 0px;
  }

  .chart__main {
    height: 85vh;
    margin-top: 55px;
    margin-left: 0px;
    width: 100vw;
  }

  .button {
    height: 35px;
  }

  .TVChartContainer {
    height: calc(100vh - 140px) !important;
  }

  .show_more {
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0px;
    position: relative;
    padding: 5px 15px;
    background-color: #1b84ff;
    color: #fff;
    border-radius: 4px;
    width: 120px;

    svg {
      margin-left: 5px;
    }
  }

  .mob__hide {
    display: none !important;
  }

  .mob_sect {
    display: flex;
    flex-direction: column;
  }

  #button-1 .knobs::before {
    height: 27px;
    width: 27px;
  }

  .cstm__dt__pick {
    .ant-picker {
      height: 38px !important;
      width: 38px !important;
      border-radius: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: $primary__blue__bg !important;
      border-radius: 6px !important;
    }

    .ant-picker-input {
      display: none !important;
    }

    .ant-picker-suffix,
    .ant-picker-clear {
      margin-left: 0px;
      color: $primary__blue__bg;
    }
  }

  .btn__add__virtualTrade {
    .mb__btn {
      background-color: $primary__blue__bg;
      color: $primary__white;
      height: 36px;
      width: 36px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 18px;
      }
    }
  }

  .btn__delete {
    display: none;
  }

  .strtgy__table__drpdwn {
    display: block;
  }

  .strategy__sml__details {
    ul {
      flex-direction: column;
      margin-top: 15px;

      li {
        flex: 0 0 auto;
        width: 100%;
        margin-bottom: 5px;
        justify-content: space-between;
      }
    }
  }

  .strategy__sml__details__bellow {
    ul {
      flex-direction: column;
      padding-right: 0;

      li {
        width: 100% !important;
        border-right: none;
        justify-content: space-between;
        border-bottom: solid 1px #efefef;
        padding: 5px 7px;
        font-size: 13px;

        &:nth-last-child(1) {
          border-bottom: none;
        }
      }
    }
  }

  .market__indicator {
    right: 10px;
    top: 10px;
  }

  .ant-table-cell {
    font-size: 13px;
    padding: 7px !important;
  }

  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
  .ant-table-tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed
    > .ant-table-wrapper:only-child
    .ant-table {
    margin: 0px 0px 0px 42px !important;
  }

  .mb-row {
    margin: 0;
  }

  .btn-action {
    border: solid 1px #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 6px;
    font-size: 14px;
  }

  .txt-xs {
    font-size: 12px;
    color: #8d8c8c;
  }

  .btn__create__strategy {
    padding: 7px 13px;
  }

  .mob_rem_margin {
    margin: 0px;
  }

  .strtgy__card__more {
    transform: translate(17px, 25px);
  }

  .cstm__drpdwn > * {
    font-size: 14px !important;
  }

  .left__bar {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 50px;
    padding: 7px;

    h2 {
      font-size: 15px !important;
      margin-bottom: 0px;
    }

    .primary__text__mute {
      font-size: 11px !important;
      display: block;
    }

    .badge__green {
      padding: 2px 10px;
    }

    .pnl__ttl {
      font-size: 14px;
    }

    .pnl__details {
      font-size: 18px;
    }

    .dwn__arrow {
      padding-right: 0px !important;
    }
  }

  .mob_spc {
    margin-left: 15px;
    margin-right: 15px;
  }

  .strtgy__card {
    margin-left: 0px !important;
    margin-right: 10px;
    width: 100%;
    padding: 5px 10px;
  }

  .strtgy__card__sub__details {
    padding: 5px 0px 0px 0px;
  }

  .vrtl__trd__badge {
    height: 20px;
    width: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .lv__trd__badge {
    height: 20px;
    width: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .portfolio__list__card div span {
    font-size: 12px;
  }

  .portfolio__list__card div h3 {
    font-size: 13px;
  }

  .portfolio__list__card {
    background-color: transparent;
    padding: 10px 12px !important;

    .sep__line {
      flex: 0 0 auto;
      width: 100%;
      margin-bottom: 5px;
      padding: 7px 10px;
      border-radius: 5px;
      background-color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .sep__line::after {
      right: 5px;
      display: none;
    }

    .sep__line::before {
      content: '';
      position: absolute;
      top: -18px;
      right: 0px;
      border-top: solid 1px #ddd;
      height: 2px;
      width: 100%;
      display: none;
    }
  }

  .mob_sect {
    padding-left: 0;
    padding-right: 0;

    .col.sep__line {
      width: 100%;
    }

    .col.sep__line__last {
      width: 100%;
      padding-left: 5px;
      padding-right: 5px;
      background-color: #fff;
      display: flex;
      border-radius: 10px;
      justify-content: space-between;
      padding: 10px;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -5px;
        border-right: solid 1px #ddd;
        height: 70px;
        width: 2px;
        display: none !important;
      }
    }
  }

  .sep__line__last {
    position: relative;
    margin-bottom: 5px;

    &::before {
      content: '';
      position: absolute;
      top: -18px;
      right: 0px;
      border-top: solid 1px #ddd;
      height: 2px;
      width: 100%;
      display: none !important;
    }

    &::after {
      content: '';
      display: none;
      position: absolute;
      bottom: -5px;
      right: 0px;
      border-top: solid 1px #ddd;
      height: 2px;
      width: 100%;
      z-index: 9;
    }
  }

  .rem_ln {
    &::after {
      display: none;
    }
  }

  .top__nv__cntrl {
    button {
      padding: 5px 5px;
      display: flex;
      background-color: #172331;
      margin-right: 5px;
      margin-left: 5px;
      border-radius: 6px;
      width: 35px;
      height: 35px;
      border: solid 1px #505357;
      font-size: 14px;

      svg {
        fill: #ddd;
      }
    }

    a {
      padding: 5px 5px;
      display: flex;
      background-color: #172331;
      margin-right: 5px;
      margin-left: 5px;
      border-radius: 6px;
      width: 35px;
      height: 35px;
      border: solid 1px #505357;
      font-size: 14px;
      align-items: center;
      justify-content: center;

      svg {
        fill: #ddd;
      }
    }
  }

  .mob__top__bar {
    background-color: #262831;
    // box-shadow: 2px 0px 3px 2px #222;
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    z-index: 999;
    padding: 5px 10px;
    margin: 0px;
  }

  .dark-theme {
    .off__blue {
      padding: 10px 10px !important;
      margin: 0px;
      margin-top: 0px !important;
    }
  }

  .off__blue {
    margin: 0px;
    padding: 10px 10px !important;
    margin-top: 10px !important;
  }

  .left__bar .strgy_ttl {
    font-size: 16px !important;
    margin-bottom: 12px;
    color: #000;
    margin-bottom: 0px;
  }

  .strtgy__card__details {
    h3 {
      font-size: 14px;
    }
  }

  .strgy_ttl_box {
    display: flex;
    justify-content: space-between;

    a {
      color: $primary__blue__bg !important;
      font-size: 14px;
    }
  }

  .rem__line__tp {
    &::before {
      display: none;
    }
  }

  .cstm__dt__pick {
    // width: 120px;
    width: auto;
  }

  .btn__exit {
    right: 128px;
  }

  .btn_swipe_exit {
    right: 170px;
    top: 10px;
  }

  .ant-picker input {
    font-size: 13px !important;
  }

  .strtgy__tbl {
    display: none !important;
  }

  .strtgy__tbl {
    height: 58px;
  }

  .button {
    width: 80px;
  }

  #button-1 .checkbox ~ .layer::after {
    content: 'Exit';
    font-size: 12px;
    padding-left: 25px;
    font-weight: 500;
  }

  #button-1 .checkbox:checked + .knobs::before {
    left: 45px;
  }

  #button-1 .checkbox:checked ~ .layer::after {
    content: 'Enter';
  }

  .strtgy__table__drpdwn {
    padding: 10px;

    .dropdown-toggle.btn.btn-primary {
      background-color: $primary__white;
      font-size: 13px;
      border-radius: 5px;
      padding: 7px 10px;
      color: $primary__blue__bg;
      font-weight: 500;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .dropdown-menu {
      a {
        font-size: 13px;
      }
    }
  }

  .mob__top__logo {
    img {
      height: 45px;
      width: 45px;
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .strtgy__table__drpdwn {
    display: block;
  }

  .mob__top__logo {
    img {
      height: 45px;
      width: 45px;
    }
  }

  .ant-picker-panel-container .ant-picker-panels {
    flex-direction: column !important;
  }

  .top__nv__cntrl {
    button {
      padding: 5px 5px;
      display: flex;
      background-color: #15171c;
      margin-right: 10px;
      margin-left: 0px;
      border-radius: 4px;
      height: 35px;
      width: 35px;
      border: solid 1px #1e2027;
    }
  }

  .strategy__sml__details.inner {
    li {
      flex-direction: column;
      position: relative;
      width: 15%;

      &:nth-child(1) {
        width: 30%;
      }

      .indicator__down {
        position: absolute;
        right: 10px;
        top: 24px;
      }

      .indicator__up {
        position: absolute;
        right: 10px;
        top: 24px;
      }
    }
  }

  .strategy__sml__details {
    margin-top: 15px;
  }

  .strategy__sml__details__bellow {
    ul {
      flex-direction: column;
      padding-right: 0;

      li {
        width: 100%;
        flex: 0 0 auto;
        border-right: none;
        justify-content: start;
        border-bottom: solid 1px #db1212;
        padding: 5px 7px;
        font-size: 13px;

        &:nth-child(1) {
          width: 100%;
        }

        &:nth-last-child(1) {
          border-bottom: none;
        }
      }
    }
  }

  .market__indicator {
    top: 10px;
  }

  .left__bar {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 50px;
    padding: 15px;

    h2 {
      font-size: 15px !important;
      margin-bottom: 0px;
    }

    .primary__text__mute {
      font-size: 12px;
      display: block;
    }

    .badge__green {
      padding: 2px 10px;
    }

    .pnl__ttl {
      font-size: 14px;
    }

    .pnl__details {
      font-size: 18px;
    }

    .dwn__arrow {
      padding-right: 0px !important;
    }
  }

  .mob_spc {
    margin-left: 15px;
    margin-right: 15px;
  }

  .strtgy__card {
    margin-left: 4px !important;
    margin-right: 4px !important;
    width: 45%;
    flex: 0 0 auto;
    margin-bottom: 5px;
  }

  .portfolio__list__card div span {
    font-size: 12px;
  }

  .portfolio__list__card div h3 {
    font-size: 13px;
  }

  .portfolio__list__card {
    .sep__line {
      flex: 0 0 auto;
      width: 33.33%;
      margin-bottom: 30px;
      padding-left: 0px;
      padding-right: 0px;
    }

    .sep__line::after {
      right: 15px;
    }

    .sep__line::before {
      content: '';
      position: absolute;
      top: -18px;
      right: 0px;
      border-top: solid 1px #ddd;
      height: 2px;
      width: 100%;
    }
  }

  .sep__line__last {
    position: relative;
    margin-bottom: 5px;

    &::before {
      content: '';
      position: absolute;
      top: -18px;
      right: 0px;
      border-top: solid 1px #ddd;
      height: 2px;
      width: 100%;
    }

    &::after {
      content: '';
      display: none;
      position: absolute;
      bottom: -5px;
      right: 0px;
      border-top: solid 1px #ddd;
      height: 2px;
      width: 100%;
      z-index: 9;
    }
  }

  .rem_ln {
    &::after {
      display: none;
    }

    &::before {
    }
  }

  .top__nv__cntrl {
    a {
      padding: 10px 15px;
      display: block;
      background-color: #eff0ff;
      margin-right: 10px;
      margin-left: 0px;
      border-radius: 4px;
    }
  }

  .mob__top__bar {
    background-color: #0d0e12;
    box-shadow: 2px 0px 3px 2px #222;
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    z-index: 999;
  }

  .left__bar .strgy_ttl {
    font-size: 18px !important;
    margin-bottom: 12px;
  }

  .strtgy__card__details {
    h3 {
      font-size: 14px;
    }
  }

  .strgy_ttl_box {
    display: flex;
    justify-content: space-between;

    a {
      color: $primary__blue__bg !important;
      font-size: 14px;
    }
  }

  .rem__line__tp {
    &::before {
      display: none;
    }
  }

  // .cstm__dt__pick {
  //   width: 120px;
  // }

  .btn__exit {
    right: 128px;
  }

  .btn_swipe_exit {
    right: 170px;
    top: 10px;
  }

  .ant-picker input {
    font-size: 13px !important;
  }

  .strtgy__tbl {
    display: none !important;
  }

  .strtgy__tbl {
    height: 58px;
  }

  .button {
    width: 80px;
  }

  #button-1 .checkbox ~ .layer::after {
    content: 'Exit';
    font-size: 12px;
    padding-left: 25px;
    font-weight: 500;
  }

  #button-1 .checkbox:checked + .knobs::before {
    left: 45px;
  }

  #button-1 .checkbox:checked ~ .layer::after {
    content: 'Enter';
  }

  .strtgy__table__drpdwn {
    padding: 10px;

    .dropdown-toggle.btn.btn-primary {
      background-color: $primary__white !important;
      font-size: 13px;
      margin-top: 0px;
      border-radius: 20px;
      padding: 7px 10px;
      margin-left: 0px;
      color: $primary__blue__bg;
      width: 150px;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .dropdown-menu {
      a {
        font-size: 14px;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 768px) and (max-width: 992px) {
  .strategy__sml__details__bellow {
    ul {
      li {
        justify-content: start;
        align-items: start;
        flex-direction: column;
        font-size: 12px;
      }
    }
  }

  .strategy__sml__details.inner {
    .indicator__down {
      position: relative;
      top: 0;
    }
  }

  .btn__back {
    height: 30px;
    width: 30px;
  }

  .indicator__down {
    right: 0px;
    top: 33px;
    position: absolute;
  }

  .strategy__sml__details {
    margin-top: 15px;
  }

  .portfolio__list__card {
    div {
      h3 {
        font-size: 14px;
      }

      span {
        font-size: 12px;
      }
    }
  }

  .sep__line {
    position: relative;
    width: 25%;
    flex: 0 0 auto;
    margin-bottom: 30px;
  }

  .strtgy__card {
    width: 40%;
  }

  .strtgy__table__box ul li span {
    padding: 10px 0px;
    display: block;
    font-size: 13px;
  }

  .portfolio__list__card {
    .sep__line {
      &:nth-child(4) {
        &::after {
          display: none;
        }
      }

      &:nth-child(1) {
        &::before {
          content: '';
          width: 100%;
          height: 1px;
          background-color: #ddd;
          position: absolute;
          bottom: -15px;
          right: 0;
        }
      }

      &:nth-child(2) {
        &::before {
          content: '';
          width: 100%;
          height: 1px;
          background-color: #ddd;
          position: absolute;
          bottom: -15px;
          right: 0;
        }
      }

      &:nth-child(3) {
        &::before {
          content: '';
          width: 100%;
          height: 1px;
          background-color: #ddd;
          position: absolute;
          bottom: -15px;
          right: 0;
        }
      }

      &:nth-child(4) {
        &::before {
          content: '';
          width: 100%;
          height: 1px;
          background-color: #ddd;
          position: absolute;
          bottom: -15px;
          right: 0;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .portfolio__list__card div h3 {
    font-size: 13px;
  }

  .portfolio__list__card div span {
    font-size: 12px;
  }

  .strtgy__card__details h3 {
    font-size: 14px;
  }

  .strtgy__card__sub__details span {
    font-size: 11px;
  }

  .sep__line::after {
    right: 0;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .sep__line {
    padding-left: 5px;
    padding-right: 5px;
  }

  .strtgy__card {
    flex: 0 0 auto;
    width: 32.9%;
    margin-bottom: 5px;
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .strategy__sml__details {
    ul {
      li {
        flex-direction: column;
        width: 33.3%;
        flex: 0 0 auto;
      }
    }
  }

  .strategy__sml__details.inner {
    ul {
      li {
        position: relative;
        flex-direction: column;
        width: 16%;
        flex: 0 0 auto;
        font-size: 12px;

        &:nth-child(1) {
          width: 30%;
        }

        .indicator__up {
          top: 25px;
          position: absolute;
          right: 10px;
        }

        .indicator__down {
          top: 25px;
          right: 10px;
        }
      }
    }
  }

  .strategy__sml__details__bellow {
    ul {
      li {
        // flex-direction: column;
        font-size: 13px;
        justify-content: start;
        flex-direction: column;
        align-items: start;

        span {
          display: block;
        }
      }
    }
  }

  .indicator__down {
    right: 0px;
    top: 33px;
    position: absolute;
  }

  .strategy__sml__details {
    ul {
      li {
        flex-direction: column;
      }
    }
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .portfolio__list__card div h3 {
    font-size: 15px;
    font-weight: 600;
  }

  .portfolio__list__card div span {
    font-size: 12px;
  }

  .strtgy__card__details h3 {
    font-size: 15px;
    margin-bottom: 4px;
    font-weight: 600;
  }

  .strtgy__card__sub__details {
    span {
      font-size: 11px;
    }
  }

  .vrtl__trd__badge {
    padding: 2px 4px;
    margin-left: 3px;
    font-size: 10px !important;
    min-width: 22px;
    text-align: center;
    display: inline-block;
  }

  .lv__trd__badge {
    padding: 2px 4px;
    margin-left: 3px;
    font-size: 10px !important;
    min-width: 22px;
    text-align: center;
  }

  // .strategy__sml__details__bellow {
  //   ul {
  //     li {
  //       &:nth-child(1) {
  //         width: 32%;
  //       }
  //     }
  //   }
  // }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .strategy__sml__details {
    ul {
      li {
        font-size: 12px;
        margin-left: 2px;
        margin-right: 2px;

        &:nth-child(1) {
          flex-direction: row;
        }

        &:nth-child(2) {
          flex-direction: row;
        }

        &:nth-child(3) {
          flex-direction: row;
        }
      }
    }
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .portfolio__list__card div span {
    font-size: 14px;
  }

  .strtgy__card__sub__details span {
    font-size: 12px;
  }

  .vrtl__trd__badge {
    padding: 2px 2px;
    margin-left: 2px;
    font-size: 11px !important;
  }

  .lv__trd__badge {
    padding: 2px 2px;
    margin-left: 2px;
    font-size: 11px !important;
  }
}
.ant-drawer {
  .ant-drawer-close {
    width: unset;
    height: unset;
  }
}
svg {
  display: unset;
}

.scrollTab {
  .nav-item {
    flex-shrink: 0;
  }
}

.trapezoid {
  height: 44px;
  width: 136px;
  position: absolute;
  top: -17px;
  clip-path: polygon(5% 97%, 95% 97%, 100% 41%, 0 40%);
  border-radius: 19px;
  text-align: center;
  padding-top: 22px;
}

.beforeImg {
  // &::before {
  //   content: '';
  //   background-image: url('/src/assets/images/quick_guide.svg');
  //   background-position: bottom left;
  //   background-repeat: no-repeat;
  //   position: absolute;
  //   z-index: 1;
  //   left: 0;
  //   bottom: 0;
  //   width: 100%;
  //   height: 100%;
  // }

  .beforeAfterImg {
    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      left: -40px;
      top: -48px;
      width: 219px;
      height: 638px;
      background: rgb(var(--color-blue-2) / 5%);
      transform: rotateZ(-27deg);
    }
    &::after {
      content: '';
      width: 421px;
      height: 114%;
      background: linear-gradient(
        173.46deg,
        rgb(var(--color-blue-2) / 5%) 0%,
        rgb(var(--color-blue-2) / 5%) 100%
      );
      position: absolute;
      right: -194px;
      z-index: 1;
      bottom: -8px;
      transform: rotateZ(222deg) rotateY(122deg) rotateX(18deg);
    }
  }

  .beforeAfterImg2 {
    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      // left: -40px;
      // top: -48px;
      // width: 219px;
      // height: 638px;
      background: rgb(var(--color-blue-2) / 5%);
      transform: rotateZ(-27deg);
    }
    &::after {
      content: '';
      // width: 421px;
      // height: 114%;
      background: linear-gradient(
        173.46deg,
        rgb(var(--color-blue-2) / 5%) 0%,
        rgb(var(--color-blue-2) / 5%) 100%
      );
      position: absolute;
      right: -194px;
      z-index: 1;
      bottom: -8px;
      transform: rotateZ(222deg) rotateY(122deg) rotateX(18deg);
    }
  }
}

.Step--Connector {
  position: absolute;
  top: 19px;
  left: -16px;
  transform: translate(-50%, 0);
  width: 100%;
  padding-left: 26px;
  padding-right: 26px;
  height: 4px;
  z-index: 0;
  .Connector--Content {
    background: transparent url('/src/assets/images/inactive_connector.png') 50%
      0 no-repeat;
    width: 100%;
    height: 100%;
  }
}
.swiperSlider {
  &:first-child {
    .Step--Connector {
      display: none;
    }
  }
}

.anouncementStrip {
  .stripTitle {
    &::after,
    &::before {
      position: absolute;
      // display: block;
      content: '';
    }
    &::before {
      bottom: 0;
      width: 60px;
      height: 100%;
      opacity: 0.5;
      right: 25px;
      background-color: currentColor;
      transform: skew(30deg);
    }
    &::after {
      top: 0;
      width: 60px;
      height: 100%;
      right: 16px;
      background-color: currentColor;
      z-index: 0;
      transform: skewX(-30deg);
    }
  }
  // .stripBg {
  //   background-image: linear-gradient(
  //     90deg,
  //     rgb(240, 138, 10) 0%,
  //     rgb(240, 138, 10) 50%,
  //     rgb(255, 195, 0) 100%
  //   );
  // }
}
