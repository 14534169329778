.strategyTabs {
  list-style-type: none;
  li {
    margin-right: 10px;
    a {
      background-color: $white;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $white;
      min-width: 38px;
      padding: 0 10px;
    }

    a.active {
      border-color: $primary_color;
      color: $primary_color;
    }
  }
}

.artcDivider {
  border-bottom: 1px solid $secondary_color;
}
.artcDividervertical {
  border-right: 1px solid $secondary_color;
  width: max-content !important;
}

.admin-link-active {
  position: relative;
  transition: 0.5s background-color ease;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $primary_color;
    bottom: -5px;
  }
}
.scrollCss {
  touch-action: manipulation;
  &::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important;
    cursor: pointer !important;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    border-radius: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary_color;
    // outline: 1px solid rgba(112, 128, 144, 0.212);
    border-radius: 20px;
  }
}
.reactLoading {
  fill: $primary_color !important;
}

.showDetail {
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  overflow: hidden;
  // "height: 0" not work with css transitions
  max-height: 250px;
  &.overlay {
    max-height: 0px;
  }
  &.accordion-content {
    // If use the "height: auto", the effect not works. Is necessary some value for the CSS create a CSS animate, and we can use "max-height" with a great value for emulate this effect.
    max-height: 800px;
    // max-height: 100%;
    -webkit-transition: max-height 2s;
    -moz-transition: max-height 2s;
    -ms-transition: max-height 2s;
    -o-transition: max-height 2s;
    transition: max-height 2s;
    &.overlay {
      max-height: 800px;
    }
  }

  &.fullMaxHeight {
    max-height: 100%;
  }
  // onlySmallScreen {
  //   @include responsivemin(768) {
  //   }
  // }
}
/* ============================================================== */

.accordionHeight {
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  overflow: hidden;
  // "height: 0" not work with css transitions
  max-height: 0;

  &.accordion-content {
    // If use the "height: auto", the effect not works. Is necessary some value for the CSS create a CSS animate, and we can use "max-height" with a great value for emulate this effect.
    max-height: 300px;
  }

  &.fullMaxHeight {
    max-height: 100%;
  }
  // onlySmallScreen {
  //   @include responsivemin(768) {
  //   }
  // }
}
