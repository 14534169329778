.react-calendar-heatmap .color-scale-1 {
  fill: #9fc891;
}
.react-calendar-heatmap .color-scale-2 {
  fill: #71c655;
}
.react-calendar-heatmap .color-scale-3 {
  fill: #4cac29;
}
.react-calendar-heatmap .color-scale-4 {
  fill: #329b0c;
}
.react-calendar-heatmap .color-scale-5 {
  fill: #cf8c8c;
  border-radius: 5px !important;
}
.react-calendar-heatmap .color-scale-6 {
  fill: #db5757;
  border-radius: 5px !important;
}
.react-calendar-heatmap .color-scale-7 {
  fill: #dd3434;
  border-radius: 5px !important;
}
.react-calendar-heatmap .color-scale-8 {
  fill: #c60707;
  border-radius: 5px !important;
}

.react-calendar-heatmap .color-empty {
  border-radius: 5px !important;
}
