.dashboardView {
  .layout-row {
    display: flex;
    flex-direction: row;
    ms-flex-direction: row;
  }

  .flex {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  #aside {
    z-index: 9;
    width: 5rem;

    .logoWrapper {
      background-color: #e6e3e3;
      padding: 12px;

      img {
        width: 50px;
      }
    }

    .navigation {
      li {
        a {
          padding-top: 20px;
          padding-bottom: 20px;
          text-align: center;
          display: block;
          color: $black;
          &:hover {
            color: $primary_color;
          }
          .userProfileImage {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }

          .customIcons {
            font-size: 24px;
          }

          .rotate90Anticlock {
            transform: rotate(-90deg);
          }
        }

        .bellIcons {
          position: relative;
          &:before {
            content: attr(data-note-count);
            color: #fff;
            width: 20px;
            height: 20px;
            background-color: red;
            border-radius: 50%;
            font-size: 9px;
            display: block;
            text-align: center;
            padding: 3px 0;
            font-weight: 500;
            position: absolute;
            right: 17px;
            top: 14px;
          }
        }
      }
    }

    .navigation.fixed {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 5rem;
    }
  }

  .page-sidenav .sticky {
    height: 100vh;
  }

  .sticky {
    position: -webkit-fixed;
    position: fixed;
  }
}

.notificationPopover {
  .ant-popover-inner-content {
    padding: 5px 4px;
  }

  .ant-popover-inner {
    border-radius: 15px;
  }

  .notificationWindow {
    height: 250px;
    width: 300px;
    background-color: $white;
    border-radius: 15px;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);

    .zeroNotification {
      height: 90%;
      color: #acaaaa;
    }
  }
}

.leftDrawers {
  z-index: 1;
  .ant-drawer-content-wrapper {
    width: 95% !important;

    @include responsivemin(1200) {
      width: 40% !important;
    }
  }
  .ant-drawer-content {
    background: $white;
  }

  .closeIcon {
    cursor: pointer;
  }

  .updateProfileButtn {
    background-color: $secondary_purple;
    color: $black;
    border: none;
    height: 40px;
    border-radius: 8px;
  }

  .profileInf {
    background-color: $gray;
    padding: 10px;
    border-radius: 8px;

    .userImageWrppr {
      width: 50px;
      height: 50px;
      overflow: hidden;
      border-radius: 8px;

      .userImage {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
    }

    h5 {
      font-size: 1.3vw;
    }

    p {
      font-size: 13px;
    }
  }

  .profileSettingTab {
    border: 1px solid #dcdcdc;
    color: $black;
    width: fit-content;
    padding: 8px 15px;
    border-radius: 30px;
    margin-right: 10px;
    background-color: #fff;
    cursor: pointer;
    font-size: 14px;
  }

  .profileSettingTab.active {
    background-color: $secondary_purple;
    border-color: transparent;
  }

  .additionalDetails {
    background-color: $white;
    padding-top: 20px;
    padding-bottom: 20px;
    position: fixed;
    bottom: 0;
    width: 97%;
    padding-right: 30px;
    @include responsivemin(1200) {
      width: 37%;
    }

    .idw {
      color: #4c64a3;
      margin-top: 20px;
      font-size: 12px;
      font-weight: 600;
    }

    .ded {
      font-size: 12px;
    }
  }

  .securities {
    .sdsee {
      border-bottom: 1px solid #cdcdcd;
      padding: 20px 0;

      &:last-child {
        border-bottom: transparent;
      }

      .seTitle {
        font-size: 14px;
      }

      .sePara {
        font-size: 12px;
        color: #9b8e8e;
      }
    }

    .securtyBttn {
      background-color: $secondary_purple;
      border-radius: 8px;
      color: $primary-color;
      border-color: $secondary_purple;
    }

    .authenticationStatus {
      background-color: #d24b4b;
      color: #fff;
      border-radius: 8px;
      cursor: not-allowed;
    }

    .authenticationStatus.enabled {
      background-color: green;
      color: #fff;
      border-radius: 8px;
      cursor: not-allowed;
    }
  }
}
